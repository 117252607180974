import routes from "@/routes";
import { lazy } from "react";
const Creative = lazy(() => import("@/modules/Asset/Creative"));
const DisplayListAsset = lazy(
  () => import("@/modules/Asset/Creative/ListAsset")
);
const CreateOrEditAsset = lazy(
  () => import("@/modules/Asset/Creative/CreateOrEditAsset")
);
const DisplayDetailAsset = lazy(
  () => import("@/modules/Asset/Creative/DetailAsset")
);

const AssetRoutes = {
  element: <Creative />,
  path: routes.ASSETS.CREATIVES.INDEX,

  children: [
    {
      element: <DisplayListAsset />,
      path: "",
    },
    {
      element: <CreateOrEditAsset />,
      path: routes.ASSETS.CREATIVES.CREATE,
    },
    {
      element: <CreateOrEditAsset isEdit />,
      path: routes.ASSETS.CREATIVES.EDIT(),
    },
    {
      element: <DisplayDetailAsset />,
      path: routes.ASSETS.CREATIVES.DETAIL(),
    },
  ],
};

export default AssetRoutes;
