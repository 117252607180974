import { createCampaign } from "@/apis/v1/campaign";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CardContainer from "@/components/Card/CardContainer";
import CustomContainer from "@/components/Form/CustomContainer";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { messages } from "@/messages";
import routes from "@/routes";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useCreateOrEditForm } from "../../campaign.hook";
import InfoForm from "../InfoForm";
import { validationSchema } from "../campaign.validation";
import { checkIfChannelExists } from "../data";
import { CAMPAIGN_TYPE } from "@/modules/Campaign/campaign.constant";

type ICreateCampaign = yup.InferType<ReturnType<typeof validationSchema>>;

export default function CreateCampaign() {
  const navigate = useNavigate();

  const { mutateAsync, isLoading } = useMutation(createCampaign);
  const { setSnackbarState } = useSnackbarDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const campaign = JSON.parse(state);

  const { rForm, switchState } = useCreateOrEditForm(campaign);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = rForm;
  async function onFormSubmit(data: ICreateCampaign) {
    try {
      if (!checkIfChannelExists(switchState)) {
        setSnackbarState({
          open: true,
          severity: "error",
          message: "At least one channel must be selected",
        });
        return;
      }
      await mutateAsync({...data , type: CAMPAIGN_TYPE.GENERAL});
      navigate(`${routes.DASHBOARD}/${routes.CAMPAIGNS.INDEX}`);
      setSnackbarState({
        open: true,
        message: messages.createdSuccessfully("Campaign"),
      });
    } catch (error: any) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.errMsg ?? messages.somethingWentWrong,
      });
    }
  }

  return (
    <CustomContainer>
      <CardContainer sx={{ p: 3 }}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <InfoForm
            control={control}
            errors={errors}
            switchState={switchState}
          />
          <Box sx={{ float: "right", marginTop: 6 }}>
            <CustomLoadingButton
              type="submit"
              isLoading={isLoading}
              title="Submit"
            />
          </Box>
        </form>
      </CardContainer>
    </CustomContainer>
  );
}
