import { Svg } from "@/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";

export default function CustomIconPopover({
  icon: Icon,
  iconSize = "medium",
  children,
}: CustomIconPopoverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box>
      <IconButton onClick={handleClick}>
        {Icon ? (
          <Icon fontSize={iconSize} />
        ) : (
          <InfoOutlinedIcon fontSize={iconSize} />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 1,
            gap: 2,
            maxWidth: 470,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              padding: 1,
              gap: 2,
            }}
          >
            {children}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

export interface CustomIconPopoverProps {
  icon?: Svg | null;
  iconSize?: "medium" | "small";
  children: React.ReactNode;
}
