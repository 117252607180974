import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import WatchedInputWrapper from "@/components/Form/WatchedInputWrapper";
import SpacingGrid from "@/components/Grid/SpacingGrid";
import { FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import { Box, Grid, Grow, Switch, Tooltip } from "@mui/material";
import { UserChildFormProps } from ".";
import { CREATE_USER_TYPE } from "../ListUser";

export default function GeneralForm({
  control,
  errors,
  resetField,
  activeStep,
  createUserType,
  companyList,
  isAdmin,
  isAdvertiser,
  isAgency,
  isEdit,
  companiesLoading,
  switchActive,
  setSwitchActive,
  advertisers,
  advertisersLoading,
}: UserChildFormProps) {
  return (
    <Grow in={activeStep === 0}>
      <Grid
        item
        container
        justifyContent="center"
        columnSpacing={FORM_COLUMN_SPACING}
        rowSpacing={FORM_ROW_SPACING}
      >
        <Grid item xs={12} md={6}>
          <CustomInput
            name="name"
            label="Name"
            control={control}
            required={true}
            error={errors?.name?.message}
            type="secondary"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            name="email"
            label="Email"
            control={control}
            required={true}
            error={errors?.email?.message}
            type="secondary"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            name="password"
            label="Password"
            control={control}
            required={true}
            error={errors?.password?.message}
            type="secondary"
            inputType="password"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            name="confirmPassword"
            label="Confirm Password"
            control={control}
            required={true}
            error={errors?.confirmPassword?.message}
            type="secondary"
            inputType="password"
          />
        </Grid>
        {isEdit ? null : isAdmin ||
          (!isAdvertiser &&
            isAgency &&
            createUserType === CREATE_USER_TYPE.ADVERTISER_USER) ? (
          <>
            <Grid item xs={12}>
              <CustomAutocomplete
                name="companyId"
                label={isAdmin ? "Select Agency" : "Select Advertiser"}
                control={control}
                itemList={companyList}
                error={errors?.companyId?.message}
                type="secondary"
                loading={companiesLoading}
              />
            </Grid>

            {isAdmin && createUserType === CREATE_USER_TYPE.ADVERTISER_USER && (
              <Grid item xs={12}>
                <WatchedInputWrapper
                  name="advertiserId"
                  control={control}
                  resetField={resetField}
                  valueToWatch="companyId"
                  isValueExpression={(val) => val}
                  alwaysRender
                  disabledField
                >
                  <CustomAutocomplete
                    name="advertiserId"
                    label={"Select Advertiser"}
                    control={control}
                    itemList={advertisers ?? []}
                    error={errors?.advertiserId?.message}
                    type="secondary"
                    loading={advertisersLoading}
                  />
                </WatchedInputWrapper>
              </Grid>
            )}
          </>
        ) : null}
        {createUserType === CREATE_USER_TYPE.INTERNAL_USER && (
          <SpacingGrid itemContainer spacingType="form">
            <Grid item xs>
              <CustomAutocomplete
                name="advertisersSpecificIds"
                label={"Restrict Advertisers"}
                control={control}
                itemList={advertisers}
                error={errors?.advertisersSpecificIds?.message}
                type="secondary"
                loading={advertisersLoading}
                disabled={!switchActive}
                isMultiple
              />
            </Grid>
            <Grid item xs={1} alignItems="flex-start">
              <Box
                sx={{ display: "flex", alignItems: "flex-end", height: "100%" }}
              >
                <Tooltip
                  title={
                    !switchActive
                      ? "You may restrict this user to a number of advertiser. If you don't want to restrict just leave it disabled."
                      : undefined
                  }
                >
                  <Switch
                    checked={switchActive}
                    onClick={() => setSwitchActive(!switchActive)}
                  />
                </Tooltip>
              </Box>
            </Grid>
          </SpacingGrid>
        )}
      </Grid>
    </Grow>
  );
}
