import appendQueryParams from "@/utils/appendQueryParams";
import client from "../client";
import { QueryFunctionContext } from "@tanstack/react-query";
import { IAudiencePayload } from "@/modules/Audience/audience.interface";
import { Channel } from "@/types";

export function createAudience(payload: IAudiencePayload) {
  return client.post("/display/audiences", payload);
}
export function createSiteVisiterAudience(payload: any) {
  return client.post("/display/audiences/general-retarget-audience", payload);
}
export function createVisitorInsightProfileAudience({ data }: { data: any }) {
  return client.post("/audiences/display/visitorInsight/profile", data);
}
export function createVisitorInsightCompanyAudience({ data }: { data: any }) {
  return client.post("/audiences/display/visitorInsight/company", data);
}
export function runVisitorInsightCompanyAudience(id: number) {
  return client.patch(`/audiences/display/${id}/visitorInsight/company/run`);
}
export function runVisitorInsightProfileAudience(id: number) {
  return client.patch(`/audiences/display/${id}/visitorInsight/profile/run`);
}
export function deleteVisitorInsightAudience({ id }: { id: any }) {
  return client.delete(`/audiences/display/${id}/visitorInsight`);
}
export function editSiteVisiterAudience({
  id,
  payload,
}: {
  id: any;
  payload: any;
}) {
  return client.put(
    `/display/audiences/general-retarget-audience/${id}`,
    payload
  );
}
export const runSiteVisiterAudience = async (id: number) => {
  return client.patch(`/display/audiences/general-retarget-audience/${id}`);
};
export function createEmailAudience(payload: IAudiencePayload) {
  return client.post("/display/audiences/email-audience", payload);
}
export const editAudience = async ({
  id,
  payload,
}: {
  id: any;
  payload: any;
}) => {
  return client.patch(`/display/audiences/modify/${id}`, payload);
};
export const editEmailAudience = async ({
  id,
  payload,
}: {
  id: any;
  payload: any;
}) => {
  return client.patch(`/display/audiences/email-audience/${id}`, payload);
};
export const refreshAudience = async (id: any) => {
  return client.patch(`/display/audiences/${id}/refresh`);
};
export function getMergedAudienes(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/audiences", data.meta?.queryParams ?? {})
  );
}
export function getEmailMergedAudienes(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      "/audiences/email-audiences",
      data.meta?.queryParams ?? {}
    )
  );
}
export function getSiteVisitorAudience(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      "/display/audiences/general-retarget-audience",
      data.meta?.queryParams ?? {}
    )
  );
}
export function getBeeswaxAccountAudience() {
  return client.get("/display/audiences");
}
export function getEmailAudience() {
  return client.get("/display/audiences/email-audience");
}
export function getAudienceById(data: QueryFunctionContext) {
  return client.get(`/display/audiences/${data.meta?.id}`);
}
export function getEmailAudienceById(data: QueryFunctionContext) {
  return client.get(`/display/audiences/email-audience/${data.meta?.id}`);
}
export function editFacebookAudience({ payload, id }: any) {
  return client.put(`/audiences/facebook/${id}`, payload);
}
export function runFacebookAudience(id: any) {
  return client.post(`/audiences/facebook/${id}/run`);
}
export function createFacebookAudience(payload: any) {
  return client.post(`/audiences/facebook`, payload);
}
export function createLinkedinRetargetingAudience({ data }: { data: any }) {
  return client.post(`/audiences/linkedin/retarget`, data);
}
export function createFacebookRetargetingAudience({ data }: { data: any }) {
  return client.post(`/audiences/facebook/retarget`, data);
}
export function runFacebookRetargetingAudience(id: any) {
  return client.post(`/audiences/facebook/retarget/${id}`);
}
export function updateLinkedinRetargetingAudience({
  data,
  id,
}: {
  data: any;
  id: any;
}) {
  return client.post(`/audiences/linkedin/retarget/${id}`, data);
}
export function runLinkedinRetargetingAudience(id: any) {
  return client.patch(`/audiences/linkedin/retarget/${id}/run`);
}
export function getRetargetMergedAudienes(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      "/audiences/retarget-audiences",
      data.meta?.queryParams ?? {}
    )
  );
}
