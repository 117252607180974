import {
  getEmailAudience,
  getEmailAudienceById,
  getEmailMergedAudienes,
} from "@/apis/v1/audience";
import { Channel } from "@/types";
import { useQuery } from "@tanstack/react-query";
type EmailMergedAudincesKeys = keyof typeof GET_EMAIL_MERGED_AUDIENCE;
export const GET_EMAIL_MERGED_AUDIENCE = {
  default: () => ["audiences", "email", "list"],
  all: (params: any) => [
    ...GET_EMAIL_MERGED_AUDIENCE.default(),
    "all",
    { ...params },
  ],
  withChannel: ({ channel, ...rest }: any) => [
    ...GET_EMAIL_MERGED_AUDIENCE.default(),
    channel,
    { ...rest },
  ],
  withAdvertiserId: ({ advertiserId, ...rest }: any) => [
    ...GET_EMAIL_MERGED_AUDIENCE.default(),
    advertiserId,
    { ...rest },
  ],
  withAdvertiserIdAndChannel: ({ channel, advertiserId, ...rest }: any) => [
    ...GET_EMAIL_MERGED_AUDIENCE.default(),
    channel,
    advertiserId,
    { ...rest },
  ],
};

export function useMergedEmailAudiences<TDATA>(
  args?: {
    queryParams?: {
      channel?: Channel;
      advertiserId?: string | number;
      isPagination?: boolean;
      page?: number;
      rowsPerPage?: number;
    };
    options?: Record<any, any>;
  },
  key: EmailMergedAudincesKeys = "withAdvertiserId"
) {
  const { queryParams = {}, options = {} } = args ?? {};
  return useQuery<TDATA>(
    GET_EMAIL_MERGED_AUDIENCE[key](queryParams),
    getEmailMergedAudienes,
    {
      meta: {
        queryParams: { ...queryParams },
      },
      ...options,
    }
  );
}

export function useGetAudienceById({
  id,
  isEnabled,
}: {
  id: any;
  isEnabled: boolean;
}) {
  return useQuery(["email", "audiences", "get", id], getEmailAudienceById, {
    meta: { id },
    enabled: isEnabled,
  });
}

export function useEmailAudience({
  enabled = true,
}: {
  enabled?: boolean;
} = {}) {
  return useQuery(["audiences", "email"], getEmailAudience, {
    enabled,
  });
}
