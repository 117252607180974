import { TMatchType } from "./conversion.interface";

export const triggerType = [
  { value: "windowLoaded", label: "Page Load" },
  { value: "customEvent", label: "Event Specific" },
];

export const valueToAppendInRules = (type: TMatchType) => ({
  matchType: type === "pageLoad" ? "pageUrl" : "",
  condition: "",
  value: "",
});
export enum EVENT_TYPE {
  PURCHASE = "0",
  LANDING_PAGE = "1",
  SIGN_UP = "2",
  LEAD = "3",
  OTHERS = "99",
}
export const eventType = [
  { label: "Purchase", value: "0" },
  { label: "Landing Page", value: "1" },
  { label: "Sign-Up", value: "2" },
  { label: "Lead", value: "3" },
  { label: "Others", value: "99" },
];

export const eventTypeLinkedin = {
  purchase: "purchase",
  landingPage: "adClick",
  signUp: "signUp",
  lead: "lead",
};

const clickWindowDispaly = [
  {
    label: "1 day",
    value: 1,
  },
  {
    label: "7 days",
    value: 7,
  },
  {
    label: "14 days",
    value: 14,
  },
  {
    label: "30 days",
    value: 30,
  },
];

const clickWindowLinkedin = [
  {
    label: "1 day",
    value: 1,
  },
  {
    label: "7 days",
    value: 7,
  },
  {
    label: "30 days",
    value: 30,
  },
  {
    label: "90 days",
    value: 90,
  },
];
export enum MATCH_TYPE {
  PAGE_URL = "Page URL",
  CLICK_TEXT = "Click Text",
  CLICK_ID = "Click ID",
  CLICK_URL = "Click URL",
  CLICK_CLASSES = "Click Classes",
}
export const matchType = [
  {
    label: "Page Url",
    value: MATCH_TYPE.PAGE_URL,
  },
  {
    label: "Click Button Text",
    value: MATCH_TYPE.CLICK_TEXT,
  },
  {
    label: "Click Button ID",
    value: MATCH_TYPE.CLICK_ID,
  },
  {
    label: "Click Button Url",
    value: MATCH_TYPE.CLICK_URL,
  },
  {
    label: "Click Button CSS Selector",
    value: MATCH_TYPE.CLICK_CLASSES,
  },
];

export const matchCondiotions = [
  {
    label: "Equals",
    value: "equals",
  },
  {
    label: "Starts with",
    value: "startsWith",
  },
  {
    label: "Contains",
    value: "contains",
  },
];

export const clickWindow = {
  linkedin: clickWindowLinkedin,
  display: clickWindowDispaly,
};
