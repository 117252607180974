import CardExpandable from "@/components/Card/CardExpandable";
import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import { CHANNELS, FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import { Box, Grid } from "@mui/material";
import { LOGO } from "../DetailCampaign/data";
import {
  FACEBOOK_OBJECTIVES,
  FACEBOOK_OBJECTIVES_LABELS,
} from "../../campaign.constant";

const PARENT_NAME = CHANNELS.FACEBOOK;
export default function FacebookForm({
  disabled,
  switchState,
  setSwitchState,
  control,
  errors,
  isEdit,
  campaign,
}: any) {
  return (
    <CardExpandable
      title="META"
      Icon={LOGO[PARENT_NAME]}
      isChecked={true}
      disabled={disabled}
      check={switchState}
      setCheck={setSwitchState}
      switchLabel={false}
      expandToggleOn="switch"
    >
      <Box sx={{ py: 3 }}>
        <Grid
          container
          columnSpacing={FORM_COLUMN_SPACING}
          rowSpacing={FORM_ROW_SPACING}
        >
          <Grid item xs={12}>
            <CustomAutocomplete
              name={`${PARENT_NAME}.objective`}
              label="Select Objective"
              control={control}
              itemList={Objectives}
              error={errors?.[PARENT_NAME]?.["objective"]?.message}
              required={true}
              type="secondary"
              disabled={!switchState || (isEdit && campaign)}
            />
          </Grid>
        </Grid>
      </Box>
    </CardExpandable>
  );
}

const Objectives = [
  {
    label: FACEBOOK_OBJECTIVES_LABELS.AWARENESS,
    value: FACEBOOK_OBJECTIVES.OUTCOME_AWARENESS,
  },
];
