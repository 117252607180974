import { checkPermission } from "@/components/Guard/PermissionGuard";
import { MODULES } from "@/constants";
import { IPermissions } from "@/modules/User/user.interface";
import routes from "@/routes";
import { lazy } from "react";
const Preset = lazy(() => import("@/modules/Presets"));
const PresetList = lazy(() => import("@/modules/Presets/List"));
const CreateOrEditTargetingExpressionPreset = lazy(
  () => import("@/modules/Presets/TargetingExpression/CreateOrEdit/")
);

const PresetRoutes = (permission: IPermissions, isAdmin: boolean) => {
  const children = [];
  const moduleArray = [MODULES.TARGETING_EXPRESSION_PRESET];
  if (
    isAdmin ||
    moduleArray.some((x) => checkPermission(permission[x]?.global, "read"))
  ) {
    children.push({
      element: <PresetList />,
      path: "",
    });
  }
  if (
    isAdmin ||
    moduleArray.some((x) => checkPermission(permission[x]?.global, "create"))
  ) {
    children.push({
      element: <CreateOrEditTargetingExpressionPreset />,
      path: routes.PRESET.TARGETIN_EXPRESSION.CREATE,
    });
  }
  if (
    isAdmin ||
    moduleArray.some((x) => checkPermission(permission[x]?.global, "update"))
  ) {
    children.push({
      element: <CreateOrEditTargetingExpressionPreset isEdit />,
      path: routes.PRESET.TARGETIN_EXPRESSION.EDIT(),
    });
  }

  return {
    element: <Preset />,
    path: routes.PRESET.INDEX,
    children: children,
  };
};

export default PresetRoutes;
