import CustomContainer from "@/components/Form/CustomContainer";
import * as React from "react";
import { useLocation } from "react-router-dom";
import CreateCampaign from "./CreateCampaign";
import EditCampaign from "./EditCampaign";

export default function CreateOrEditCampaign({
  isEdit = false,
}: CreateOrEditConversionProps): ReturnType<React.FC> {
  const location = useLocation();
  const campaign = JSON.parse(location?.state?.data ?? null);
  return (
    <CustomContainer>
      {" "}
      {isEdit ? (
        campaign && <EditCampaign campaign={campaign} />
      ) : (
        <CreateCampaign />
      )}{" "}
    </CustomContainer>
  );
}

interface CreateOrEditConversionProps {
  isEdit?: boolean;
}
