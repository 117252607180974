import { messages } from "@/messages";
import * as yup from "yup";
import { FACEBOOK_OBJECTIVES } from "../campaign.constant";
export const validationSchema = ({
  linkedin,
  display,
  facebook,
}: {
  linkedin: boolean;
  display: boolean;
  facebook: boolean;
}) =>
  yup.object({
    name: yup.string().required(messages.requiredField),
    advertiserCompanyId: yup.string().required(messages.requiredField),
    startDate: yup
      .date()
      .typeError(messages.dateError)
      .min(new Date())
      .required(messages.requiredField)
      .nullable(),
    linkedin: yup.object(
      linkedin
        ? {
            objectiveTypeId: yup.string().required(messages.requiredField),
          }
        : {}
    ),
    display: yup.object(
      display
        ? {
            continents: yup
              .array()
              .min(1, messages.minimumValues("continents"))
              .required(messages.requiredField),
            budgetType: yup
              .string()
              .required(messages.requiredField)
              .nullable(),
            budgetValue: yup
              .number()
              .required(messages.requiredField)
              .nullable(),
          }
        : {}
    ),
    facebook: yup.object(
      facebook
        ? {
            objective: yup
              .string()
              .oneOf([FACEBOOK_OBJECTIVES.OUTCOME_AWARENESS])
              .required(messages.requiredField),
          }
        : {}
    ),
  });
