import CustomAutocomplete, {
  TCustomAutocomplete,
} from "@/components/Form/InputFields/CustomAutocomplete";
import CustomListWithImageButtonForAutocomplete from "@/components/List/CustomListWithImageButtonForAutocomplete";
import { useDialogDispatch } from "@/context/DialogContext";
import { ImageLoadingState, TName } from "@/types";
import { Box } from "@mui/material";
import * as React from "react";
import { Control } from "react-hook-form";

export default function CustomAutocompleteWithImageButtonList({
  itemList,
  name,
  loading = false,
  control,
  ...rest
}: CustomAutocompleteWithImageButtonListProps) {
  const { setDialogState } = useDialogDispatch();
  const memoedListObject = React.useMemo(() => {
    const obj: Record<number | string, any> = {};
    if (itemList) {
      itemList.forEach((item, index) => {
        obj[index] = item;
      });
    }
    return obj;
  }, [itemList]);

  const handleClickOpenImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number,
    {
      imageUrl,
      loadedStatus,
      urlsLoading,
      dimentions,
    }: {
      imageUrl: string;
      loadedStatus: ImageLoadingState;
      urlsLoading: boolean;
      dimentions: {
        width: number;
        height: number;
      };
    }
  ) => {
    event.stopPropagation();
    if (!urlsLoading) {
      setDialogState({
        open: true,
        title: "Creative",
        children: (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <iframe
              src={imageUrl}
              style={{
                overflow: "hidden",
                border: "none",
                height: `calc(${dimentions?.height ?? 500}px + 50px)`,
                width: `calc(${dimentions?.width ?? 500}px + 50px)`,
              }}
            />
          </Box>
        ),
      });
    }
  };

  return (
    <>
      <CustomAutocomplete
        {...rest}
        isCustomList={true}
        isMultiple={true}
        name={name as TName<any>}
        control={control}
        itemList={itemList}
        virtualize={false}
        loading={loading}
        customList={CustomListWithImageButtonForAutocomplete}
        listboxProps={{
          customProps: {
            handleClickOpenImage,
            list: memoedListObject,
          },
        }}
      />
    </>
  );
}

interface CustomAutocompleteWithImageButtonListProps
  extends TCustomAutocomplete {
  control: Control<any>;
  name: string;
}
