import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import { FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import usePermission from "@/hooks/usePermission";
import { messages } from "@/messages";
import { TName } from "@/types";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useWatch } from "react-hook-form";
import { isRenderComponent, scopeValues } from "../data";
import {
  CommonFieldComponentProps,
  ComponentData,
  NodeComponentProps,
  ScopeList,
} from "../user.interface";
export default function ModuleAccessPermission(
  props: CommonFieldComponentProps
) {
  const { componentData, permissions, createUserType, isEdit = false } = props;

  return (
    <Grid item xs={12}>
      <Grid
        item
        container
        justifyContent="center"
        columnSpacing={FORM_COLUMN_SPACING}
        rowSpacing={FORM_ROW_SPACING}
      >
        {componentData.map((x: ComponentData, i: number) => {
          if (
            isRenderComponent({ data: x, permissions, createUserType, isEdit })
          ) {
            return <Component key={i} {...props} data={x} />;
          }
        })}
      </Grid>
    </Grid>
  );
}

function Component({
  control,
  errors,
  permissions,
  data,
  isEdit,
  isAgencyAdminCreationOrEdit,
}: NodeComponentProps) {
  const watched = useWatch({
    name: data.dependent as TName<any>,
    control,
    disabled: !data.dependent,
  });
  const componentValue = useWatch({
    name: data.name as TName<any>,
    control,
    disabled: !isEdit,
  });
  const { permission: creatorOrEditorPermissions } = usePermission();
  let isDisable = false;
  if (isEdit) {
    const editorsModulePermission = creatorOrEditorPermissions[data.permission];
    if (
      editorsModulePermission &&
      componentValue &&
      componentValue !== "none"
    ) {
      if (editorsModulePermission.global.length < componentValue.length)
        isDisable = true;
    }
  }
  const scopeItemList = scopeValues(
    permissions[data.permission]?.global,
    data.valueToFilter?.(isAgencyAdminCreationOrEdit)
  );
  const mutatedScopeItemList =
    data?.labelModifier?.(scopeItemList as ScopeList) ?? scopeItemList;
  return (
    <Tooltip title={isDisable && messages.notAuthorized} placement="top">
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "200px" }}>
            <Typography variant="body2Reg">{data.label}:</Typography>
          </Box>
          <Box sx={{ width: "100%" }}>
            <CustomAutocomplete
              name={data.name}
              placeholder={data?.placeholders?.[0] ?? "Select Access Level"}
              control={control}
              itemList={mutatedScopeItemList}
              error={errors?.[data.name]?.message}
              type="secondary"
              disabled={isDisable || data?.dependentCondition?.(watched)}
            />
          </Box>
        </Box>
      </Grid>
    </Tooltip>
  );
}
