import { LINKEDIN_AD_FORMAT_TYPE } from "@/modules/Campaign/GeneralCampaign/DetailCampaign/Bidding/data";
import { IAsset } from "../asset.interface";
import { AdFormatTypeUnion, AllowedCreativeType } from "./types";

export const channelList = [
  {
    label: "Linkedin",
    value: "linkedin",
  },
  {
    label: "Display",
    value: "display",
  },
];

export const callToActionButton = [
  {
    label: "Apply Now",
    value: "APPLY",
  },
  {
    label: "Download",
    value: "DOWNLOAD",
  },
  {
    label: "Get Quote",
    value: "VIEW_QUOTE",
  },
  {
    label: "Learn More",
    value: "LEARN_MORE",
  },
  {
    label: "Sign Up",
    value: "SIGN_UP",
  },
  {
    label: "Subscribe",
    value: "SUBSCRIBE",
  },
  {
    label: "Register",
    value: "REGISTER",
  },
];

export const displayAdSizes = [
  "88x31",
  "120x60",
  "120x240",
  "120x600",
  "125x125",
  "160x600",
  "180x150",
  "200x200",
  "234x60",
  "250x250",
  "300x50",
  "300x250",
  "300x600",
  "300x1050",
  "320x50",
  "320x100",
  "320x480",
  "336x280",
  "468x60",
  "480x320",
  "728x90",
  "768x1024",
  "970x90",
  "970x250",
  "1024x768",
];

export const linkedinAdSizes = {
  horizontal: {
    minWidth: 640,
    maxWidth: 7680,
    minHeight: 360,
    maxHeight: 43320,
  },
  square: {
    minWidth: 360,
    maxWidth: 360,
    minHeight: 4320,
    maxHeight: 4320,
  },
  vertical: {
    minWidth: 360,
    maxWidth: 1254,
    minHeight: 640,
    maxHeight: 2400,
  },
};

export enum CreativeType {
  IMAGE = "image",
  TEXT = "text",
  VIDEO = "video",
  MULTI_IMAGE = "multi-image",
  MULTI_VIDEO = "multi-video",
  HTML = "html",
  JS_TAG = "js-tag",
  CAROUSAL = "carousel",
}

export const internalCreativeTypeToLinkedinCreativeTypeMapping: Record<
  AllowedCreativeType,
  AdFormatTypeUnion
> = {
  [CreativeType.IMAGE]: LINKEDIN_AD_FORMAT_TYPE.IMAGE,
  [CreativeType.TEXT]: LINKEDIN_AD_FORMAT_TYPE.TEXT,
  [CreativeType.VIDEO]: LINKEDIN_AD_FORMAT_TYPE.VIDEO,
  [CreativeType.CAROUSAL]: LINKEDIN_AD_FORMAT_TYPE.CAROUSEL,
  [CreativeType.MULTI_IMAGE]: LINKEDIN_AD_FORMAT_TYPE.IMAGE,
  [CreativeType.MULTI_VIDEO]: LINKEDIN_AD_FORMAT_TYPE.VIDEO,
};
export const internalLinkedinCreativeToInternalCreativeMapping: Record<
  AdFormatTypeUnion,
  AllowedCreativeType
> = {
  [LINKEDIN_AD_FORMAT_TYPE.IMAGE]: CreativeType.IMAGE,
  [LINKEDIN_AD_FORMAT_TYPE.TEXT]: CreativeType.TEXT,
  [LINKEDIN_AD_FORMAT_TYPE.VIDEO]: CreativeType.VIDEO,
  [LINKEDIN_AD_FORMAT_TYPE.CAROUSEL]: CreativeType.CAROUSAL,
};
export const creativeTypeOptionsBeeswax = [
  {
    label: "Image",
    value: CreativeType.IMAGE,
  },
  {
    label: "Video",
    value: CreativeType.VIDEO,
  },
  {
    label: "HTML",
    value: CreativeType.HTML,
  },
  {
    label: "Multi-Image",
    value: CreativeType.MULTI_IMAGE,
  },
  {
    label: "Multi-Video",
    value: CreativeType.MULTI_VIDEO,
  },
  {
    label: "JS Tag",
    value: CreativeType.JS_TAG,
  },
];

export const creativeTypeOptionsLinkedin = [
  {
    label: "Image",
    value: CreativeType.IMAGE,
  },
  {
    label: "Video",
    value: CreativeType.VIDEO,
  },
  {
    label: "Text",
    value: CreativeType.TEXT,
  },
  {
    label: "Carousal",
    value: CreativeType.CAROUSAL,
  },
  {
    label: "Multi-Image",
    value: CreativeType.MULTI_IMAGE,
  },
  {
    label: "Multi-Video",
    value: CreativeType.MULTI_VIDEO,
  },
];

export const creativeTypeOptionsFacebook = [
  {
    label: "Image",
    value: CreativeType.IMAGE,
  },
  {
    label: "Video",
    value: CreativeType.VIDEO,
  },
];

export enum AdServerMacros {
  NONE = "none",
  DOUBLE_CLICK_INS = "ins",
  AUTO_DETECT = "auto_detect",
  FACEBOOK_ATLAS = "atlas",
  CAMPAIGN_MANAGER = "dfa",
  FLASH_TALKING = "flash_talking",
  SIZMEK = "sizmek",
}

export const AdServerMacrosOptions = [
  {
    label: "None",
    value: AdServerMacros.NONE,
  },
  {
    label: "DoubleClick INS Tag",
    value: AdServerMacros.DOUBLE_CLICK_INS,
  },
  {
    label: "Auto-Detect Ad Server",
    value: AdServerMacros.AUTO_DETECT,
  },
  {
    label: "Facebook Atlas Tags",
    value: AdServerMacros.FACEBOOK_ATLAS,
  },
  {
    label: "Campaign Manager 360",
    value: AdServerMacros.CAMPAIGN_MANAGER,
  },
  {
    label: "FlashTalking Tags",
    value: AdServerMacros.FLASH_TALKING,
  },
  {
    label: "Sizmek Tags",
    value: AdServerMacros.SIZMEK,
  },
];

export function getFileExtensionAccordingToType(creativeType: any) {
  switch (creativeType) {
    case CreativeType.MULTI_IMAGE:
    case CreativeType.MULTI_VIDEO:
      return ".zip";
    case CreativeType.HTML:
      return ".html";
    case CreativeType.VIDEO:
      return ".mp4";
    default:
      return ".jpg, .jpeg, .png, .gif";
  }
}

export function getCreativeType(asset: IAsset) {
  if (asset?.assetInfo && asset?.assetInfo?.type) {
    const assetType = asset?.assetInfo?.type;
    if (assetType.includes("video")) {
      return CreativeType.VIDEO;
    } else if (assetType.includes("image")) {
      return CreativeType.IMAGE;
    } else if (assetType.includes("text")) {
      return CreativeType.HTML;
    } else if (assetType.includes("zip")) {
      if (asset?.assetInfo?.mimeType === "image") {
        return CreativeType.MULTI_IMAGE;
      }
      return CreativeType.MULTI_VIDEO;
    }
  } else if (asset?.creativeRuleKey && asset?.creativeContentTag) {
    return CreativeType.JS_TAG;
  }
}

export const jsTagDimensions = [
  "160x600",
  "300x250",
  "300x600",
  "320x50",
  "320x480",
  "480x320",
  "728x90",
  "768x1024",
  "970x250",
  "0x0",
  "1x1",
  "88x31",
  "120x60",
  "120x240",
  "120x600",
  "125x125",
  "180x150",
  "200x200",
  "234x60",
  "250x250",
  "300x50",
  "300x1050",
  "320x100",
  "336x280",
  "468x60",
  "970x90",
  "1024x768",
];

export const getCreativeAddonsOptions = (creativeAddonsList: any) => {
  return creativeAddonsList.map((creativeAddon: any) => ({
    label: creativeAddon?.creative_addon_name,
    value: creativeAddon?.creative_addon_id,
  }));
};
