import { messages } from "@/messages";
import * as yup from "yup";

export const validationSchema = (isEdit: boolean) =>
  yup.object({
    name: yup.string().required(messages.requiredField),
    executionType: yup.string().required(messages.requiredField),
    status: yup.boolean().required(messages.requiredField),
    advertiserCompanyId: yup
      .string()
      .required(messages.requiredField)
      .nullable(),
    lineItemType: yup.string().required(messages.requiredField).nullable(),
    totalImpression: yup.number().required(messages.requiredField).nullable(),
    numberOfAds: yup.number().required(messages.requiredField).nullable(),
    durationType: yup.number().nullable(),

    startDate: isEdit
      ? yup
          .date()
          .typeError(messages.dateError)
          .required(messages.requiredField)
          .nullable()
      : yup
          .date()
          .typeError(messages.dateError)
          .min(new Date())
          .required(messages.requiredField)
          .nullable(),
    dateType: yup.string().required(messages.requiredField),
    endDate: yup
      .date()
      .typeError(messages.dateError)
      .test("data-validation", messages.endDateBeforeStart, (value, ctx) =>
        value ? value >= ctx.parent.startDate : true
      )
      .notRequired()
      .nullable(),
    audienceIds: yup.string().required(messages.requiredField),
    creatives: yup
      .array()
      .min(0, messages.minimumValues("ad"))
      .required(messages.requiredField),
  });
