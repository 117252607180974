import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export function createLineItem({
  campaignId,
  payload,
}: {
  campaignId: number | string;
  payload: any;
}) {
  return client.post(`/campaigns/${campaignId}/lineItems`, payload);
}
export function createFacebookLineItem({
  payload,
}: {
  campaignId: number | string;
  payload: any;
}) {
  return client.post(`/line-item`, payload);
}
export function editFacebookLineItem({
  payload,
  lineItemId,
}: {
  lineItemId: number | string;
  payload: any;
}) {
  return client.patch(`/line-item/${lineItemId}`, payload);
}
export function editLineItem({
  lineItemId,
  payload,
}: {
  lineItemId: number | string;
  payload: any;
}) {
  return client.patch(`/lineItems/${lineItemId}`, payload);
}

export function getLineItems(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/lineItems`, data.meta?.queryParams ?? {})
  );
}

export function getFacebookLineItem(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `/line-item/facebook/${data?.meta?.id}`,
      data.meta?.queryParams ?? {}
    )
  );
}
export function getExternalLineItem(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/lineItems/external`, data.meta?.queryParams ?? {})
  );
}
export function getLinkedinLineItems(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`linkedin/campaigns`, data.meta?.queryParams ?? {})
  );
}
