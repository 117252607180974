import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import { useUserContext } from "@/context/UserContext";
import { useAdvertisersList } from "@/modules/Advertiser/advertiser.hook";
import { useListInsightAudience } from "@/modules/Asset/Creative/asset.hook";
import { Channel, InputFieldType } from "@/types";
import { Grid } from "@mui/material";
import { useWatch } from "react-hook-form";
import { IFormProps } from "../campaign.interface";
import { CampaignType, radioOptions } from "./data";

export default function PrimaryDetails({
  control,
  errors,
  advertiserId,
  isEdit = false,
  type,
}: PrimaryDetails) {
  const { loggedInAdvertiserId } = useUserContext();
  const { data: advertisers, isLoading: advertisersLoading } =
    useAdvertisersList();
  const watchexecutionType = useWatch({
    control,
    name: "executionType",
  });
  const {
    data: audienceList,
    isLoading: audienceListLoading,
    isFetching: audienceListFetching,
  } = useListInsightAudience({
    type,
    enabled: Boolean(advertiserId) && Boolean(watchexecutionType),
    queryParams: {
      advertiserId,
      executionType: watchexecutionType,
    },
  });

  return (
    <>
      <Grid item xs={6}>
        <CustomAutocomplete
          name="advertiserCompanyId"
          label="Select Advertiser"
          control={control}
          itemList={advertisersList(advertisers)}
          error={errors?.advertiserCompanyId?.message}
          required={true}
          loading={advertisersLoading}
          disabled={Boolean(loggedInAdvertiserId) || isEdit}
          type="secondary"
        />
      </Grid>
      <Grid item xs={6}>
        <CustomAutocomplete
          name="executionType"
          label="Campaign Type"
          control={control}
          itemList={CampaignType}
          required={true}
          disableClearable
          type="secondary"
          disabled={Boolean(loggedInAdvertiserId) || isEdit}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomAutocomplete
          name="audienceIds"
          label="Audience"
          itemList={audienceListMapping(audienceList)}
          control={control}
          type="secondary"
          required={true}
          error={errors?.audienceIds?.message}
          loading={audienceListLoading && audienceListFetching}
          disabled={
            Boolean(loggedInAdvertiserId) || isEdit || !watchexecutionType
          }
        />
      </Grid>
      <Grid item xs={6}>
        <CustomAutocomplete
          name="dateType"
          label="Campaign Period"
          control={control}
          itemList={radioOptions}
          required={true}
          disableClearable
          type="secondary"
          disabled={Boolean(loggedInAdvertiserId) || isEdit}
        />
      </Grid>
    </>
  );
}

function audienceListMapping(data: any) {
  return data?.rows?.map((item: any) => ({
    label: item?.segmentName,
    value: item?.id,
  }));
}

function advertisersList(data: any) {
  return data
    ?.filter((item: any) => item.dspAccountId !== null)
    .map((item: any) => ({ label: item.name, value: item.id }));
}
interface PrimaryDetails extends IFormProps {
  type: Channel;
  isMultiple?: boolean;
  noContainer?: boolean;
  inputType?: InputFieldType;
  name?: string;
  errorMessage?: string;
  advertiserId: number;
  audienceType?: any;
  activeStep?: any;
  switchActive?: any;
  setSwitchActive?: any;
  isEdit: any;
}
