import routes from "@/routes";
import { lazy } from "react";
const Template = lazy(() => import("@/modules/Asset/Template"));
const EditTemplate = lazy(
  () => import("@/modules/Asset/Template/EditTemplate")
);

const ListTemplate = lazy(
  () => import("@/modules/Asset/Template/ListTemplate")
);
const TemplateDetail = lazy(
  () => import("@/modules/Asset/Template/TemplateDetail")
);
const TemplateRoutes = {
  element: <Template />,
  path: routes.ASSETS.TEMPLATE.INDEX,

  children: [
    {
      element: <ListTemplate />,
      path: "",
    },
    {
      element: <EditTemplate />,
      path: routes.ASSETS.TEMPLATE.EDIT(),
    },
    {
      element: <TemplateDetail />,
      path: routes.ASSETS.TEMPLATE.DETAIL(),
    },
  ],
};

export default TemplateRoutes;
