import { capitalize } from "lodash";
import { ChannelSwitchState } from "../campaign.interface";
import { Channel } from "@/types";

export const campaignTypeList = (objectiveTypes: any) =>
  objectiveTypes?.map((item: any) => ({
    label: convertCase(item.name),
    value: item.id,
  }));

function convertCase(value: string) {
  return value
    .split("_")
    .map((item) => capitalize(item))
    .join(" ");
}

export function checkIfChannelExists(switchState: ChannelSwitchState) {
  return Object.keys(switchState).some(
    (channel) => switchState[channel as Channel].state
  );
}
