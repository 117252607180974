export default function filterDirtyFields(
  data: Record<string, any>,
  dirtyFields: Record<string, any>
) {
  const obj: Record<string, any> = {};
  return recursiveLoop(data, dirtyFields, obj);
}

function recursiveLoop(
  data: Record<string, any>,
  dirtyFields: Record<string, any>,
  obj: any
) {
  for (const key in dirtyFields) {
    if (
      typeof dirtyFields[key] === "object" &&
      Object.keys(dirtyFields[key]).length > 0
    ) {
      obj[key] = recursiveLoop(data[key], dirtyFields[key], {});
    } else {
      obj[key] = data[key];
    }
  }

  return obj;
}
