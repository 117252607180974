import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CardContainer from "@/components/Card/CardContainer";
import CustomContainer from "@/components/Form/CustomContainer";
import CustomSwitchSecondary from "@/components/Form/CustomSwitchSecondary";
import CustomStepper from "@/components/Stepper";
import { CHANNELS, FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import { Box, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { UseFormTrigger, useWatch } from "react-hook-form";
import { IFormProps } from "../campaign.interface";
import { STEP_BUTTON, STEPFORM, STEPS, STEPS_LABEL } from "./data";

const TYPE = CHANNELS.DISPLAY;

export default function InfoForm({
  control,
  errors,
  isEdit = false,
  onFormSubmit,
  trigger,
  isLoading,
  getValues,
  setValue,
}: InfoFormProps) {
  const [step, setStep] = useState<(typeof STEPS)[number]>(STEPS[0]);
  const [switchActive, setSwitchActive] = useState(false);
  const Form = STEPFORM[step as 0 | 1];

  const watchAdvertiserValue = useWatch({
    name: "advertiserCompanyId",
    control,
  });

  const valuesToTrigger = () => {
    const triggerValues = ["advertiserCompanyId", "campaignType"];
    return triggerValues;
  };

  const handleNext = async () => {
    const isValid = await trigger(valuesToTrigger());
    if (isValid) {
      setStep((currentValue) =>
        currentValue < STEPS.length ? STEPS[step + 1] : STEPS[step]
      );
    }
  };
  const handleBack = () => {
    setStep((currentValue) =>
      currentValue < STEPS.length ? STEPS[step - 1] : STEPS[step]
    );
  };

  return (
    <CustomContainer>
      <CardContainer sx={{ p: 3 }}>
        <Box sx={{ pb: 5 }}>
          <CustomStepper steps={STEPS_LABEL} activeStep={step} />
        </Box>
        <form onSubmit={onFormSubmit}>
          <Grid
            container
            columnSpacing={FORM_COLUMN_SPACING}
            rowSpacing={FORM_ROW_SPACING}
          >
            {
              <Form
                control={control}
                errors={errors}
                activeStep={step}
                switchActive={switchActive}
                setSwitchActive={setSwitchActive}
                isEdit={isEdit}
                channel={CHANNELS.DISPLAY}
                advertiserId={watchAdvertiserValue}
                type={TYPE}
                getValues={getValues}
                setValue={setValue}
              />
            }
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 6,
            }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="body1Semi" sx={{ marginRight: "20px" }}>
                Activate{" "}
              </Typography>
              <CustomSwitchSecondary
                name="status"
                control={control}
                error={errors?.status?.message}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <CustomLoadingButton
                title={STEP_BUTTON[step]}
                isLoading={false}
                onClick={step === 0 ? handleNext : handleBack}
              />
              <CustomLoadingButton
                type="submit"
                isLoading={isLoading}
                title={isEdit ? "Update" : "Submit"}
                disabled={step === STEPS[0]}
              />
            </Box>
          </Box>
        </form>
      </CardContainer>
    </CustomContainer>
  );
}
interface InfoFormProps extends IFormProps {
  isEdit?: boolean;
  trigger: UseFormTrigger<any>;
  onFormSubmit: () => Promise<void>;
  isLoading: boolean;
}
