import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import CustomTable from "@/components/Table/CustomTable";
import NavMenu from "@/components/menu/navMenu";
import { MODULES } from "@/constants";
import { useFilterContext } from "@/context/FilterContext";
import { useUserContext } from "@/context/UserContext";
import useFilterHeaders from "@/hooks/useFilterHeaders";
import useMenu from "@/hooks/useMenu";
import usePagination from "@/hooks/usePagination";
import usePermission from "@/hooks/usePermission";
import { useAgenciesAndAdvertisersList } from "@/modules/Company/company.hook";
import routes from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box } from "@mui/material";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useUsersList } from "../user.hook";
import UserTableRow from "./TableRow";

const headers = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "company", label: "Company" },
  { id: "createdAt", label: "Created At" },
  { id: "action", label: "Actions", noSort: true },
];
export const CREATE_USER_TYPE = {
  INTERNAL_USER: "AGENCY",
  ADVERTISER_USER: "ADVERTISER",
} as const;

type CreateUserKey = keyof typeof CREATE_USER_TYPE;
export type CreateUserType = (typeof CREATE_USER_TYPE)[CreateUserKey];

const schema = yup.object({
  advertiserId: yup.number().nullable().optional(),
});

export default function ListUser(): ReturnType<React.FC> {
  const { loggedInAdvertiserId } = useUserContext();
  const { pathname } = useLocation();
  const { getFiltersForPath, updateFilter } = useFilterContext();

  // Retrieve specific filters for the current path from context
  const currentFilters: any = getFiltersForPath(pathname);

  const { control, watch } = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      advertiserId: loggedInAdvertiserId
        ? loggedInAdvertiserId
        : currentFilters.advertiserId
        ? currentFilters.advertiserId
        : null,
    },
    resolver: yupResolver(schema),
  });

  const advertiserId = watch("advertiserId", loggedInAdvertiserId ?? null);

  React.useEffect(() => {
    updateFilter(pathname, { advertiserId });
  }, [advertiserId]);
  const {
    data: agenciesAndAdvertisers,
    isLoading: agenciesAndAdvertisersLoading,
  } = useAgenciesAndAdvertisersList();

  const { isAdmin, isAdvertiser, isAgencyAdmin, isAgencyUser, permission } =
    usePermission();
  const pagination = usePagination();
  const { data: users, isLoading: usersLoading } = useUsersList({
    queryParams: {
      ...(advertiserId ? { advertiserId } : { type: "all" }),
      page: pagination.page,
      size: pagination.rowsPerPage,
    },
  });
  const { open, anchorEl, handleClick, handleClose } = useMenu();
  const navigate = useNavigate();

  const userCreatePath = `${routes.USERS.INDEX}/${routes.USERS.CREATE}`;
  const userCreateAbsolutePath = isAdmin
    ? `${routes.DASHBOARD}/setup/${userCreatePath}`
    : `${routes.DASHBOARD}/${userCreatePath}`;

  const handleClickSecondary = (state: CreateUserType) => {
    navigate(userCreateAbsolutePath, {
      state,
    });
  };

  React.useEffect(() => {
    pagination.handleResetPagination();
  }, [advertiserId]);

  const permittedHeader = useFilterHeaders({
    headers,
    module: isAdvertiser ? MODULES.ADVERTISER_USERS : MODULES.AGENCY_USERS,
    scope: ["read", "update", "delete"],
  });
  function handler() {
    if (isAdvertiser) {
      return () => handleClickSecondary(CREATE_USER_TYPE.ADVERTISER_USER);
    }
    if (isAdmin) {
      return handleClick;
    }
    if (isAgencyAdmin || isAgencyUser) {
      if (
        permission[MODULES.AGENCY_USERS] &&
        permission[MODULES.ADVERTISER_USERS]
      ) {
        return handleClick;
      } else if (permission[MODULES.ADVERTISER_USERS]) {
        return () => handleClickSecondary(CREATE_USER_TYPE.ADVERTISER_USER);
      } else return () => handleClickSecondary(CREATE_USER_TYPE.INTERNAL_USER);
    }
  }
  const handleOnClick = handler();
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ maxWidth: "350px", marginBottom: "6px" }}>
        <CustomAutocomplete
          name="advertiserId"
          control={control}
          placeholder={
            isAdvertiser ? "Select Advertiser" : "Select Agency or Advertiser"
          }
          loading={agenciesAndAdvertisersLoading}
          disabled={
            Boolean(loggedInAdvertiserId) || agenciesAndAdvertisersLoading
          }
          itemList={agenciesAndAdvertisers?.rows?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Box>
      <CustomTable
        title="Users"
        buttonName="Create"
        ButtonIcon={AddOutlinedIcon}
        buttonFunction={handleOnClick}
        headers={permittedHeader}
        rowData={users?.rows}
        totalCount={users?.count}
        tableRowComponent={UserTableRow}
        primaryButtonPermission={{
          module: [MODULES.AGENCY_USERS, MODULES.ADVERTISER_USERS],
          permissionType: "create",
        }}
        isLoading={usersLoading}
        {...pagination}
      />
      <NavMenu
        list={menuList({ navigate, userCreateAbsolutePath })}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </Box>
  );
}

const menuList = ({ navigate, userCreateAbsolutePath }: any) => [
  {
    label: "Internal User",
    Icon: AddOutlinedIcon,
    onClick: () =>
      navigate(userCreateAbsolutePath, {
        state: CREATE_USER_TYPE.INTERNAL_USER,
      }),
  },
  {
    label: "Advertiser User",
    Icon: AddOutlinedIcon,
    onClick: () =>
      navigate(userCreateAbsolutePath, {
        state: CREATE_USER_TYPE.ADVERTISER_USER,
      }),
  },
];
