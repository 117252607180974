export const LINKEDIN_OPTIMIZATION_GOAL = {
  REACH: "Reach",
  IMPRESSIONS: "Impressions",
  LEADS: "Leads",
  LANDING_PAGE_CLICKS: "Landing Page Clicks",
  ENGAGEMENT_CLICKS: "Engagement Clicks",
} as const;

export const LINKEDIN_CAMPAIGN_OBJECTIVE_TYPE = {
  BRAND_AWARENESS: "BRAND_AWARENESS",
  WEBSITE_VISIT: "WEBSITE_VISIT",
  LEAD_GENERATION: "LEAD_GENERATION",
  ENGAGEMENT: "ENGAGEMENT",
} as const;

// Referred as the Bidding Strategy on UI
export const LINKEDIN_OPTIMIZATION_TARGET_TYPE = {
  NONE: "NONE",
  MAX_REACH: "MAX_REACH",
  MAX_IMPRESSIONS: "MAX_IMPRESSIONS",
  MAX_CLICK: "MAX_CLICK",
  MAX_LEAD: "MAX_LEAD",
  MAX_CONVERSION: "MAX_CONVERSION",
  ENHANCED_CONVERSION: "ENHANCED_CONVERSION",
  TARGET_COST_PER_IMPRESSION: "TARGET_COST_PER_IMPRESSION",
  TARGET_COST_PER_CLICK: "TARGET_COST_PER_CLICK",
  CAP_COST_AND_MAXIMIZE_CLICKS: "CAP_COST_AND_MAXIMIZE_CLICKS",
  CAP_COST_AND_MAXIMIZE_LEADS: "CAP_COST_AND_MAXIMIZE_LEADS",
} as const;

export const LINKEDIN_AD_FORMAT_TYPE = {
  IMAGE: "STANDARD_UPDATE",
  VIDEO: "SINGLE_VIDEO",
  TEXT: "TEXT_AD",
  CAROUSEL: "CAROUSEL",
} as const;

export const LINKEDIN_COST_TYPE = {
  CPM: "CPM",
  CPC: "CPC",
};

export const LINKEDIN_CAMPAIGN_OPTIMIZATION_MAPPING = {
  [LINKEDIN_CAMPAIGN_OBJECTIVE_TYPE.BRAND_AWARENESS]: {
    [LINKEDIN_AD_FORMAT_TYPE.IMAGE]: {
      [LINKEDIN_OPTIMIZATION_GOAL.REACH]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_REACH,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_IMPRESSIONS,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_IMPRESSION,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.CAROUSEL]: {
      [LINKEDIN_OPTIMIZATION_GOAL.REACH]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_REACH,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_IMPRESSIONS,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_IMPRESSION,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.VIDEO]: {
      [LINKEDIN_OPTIMIZATION_GOAL.REACH]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_REACH,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_IMPRESSIONS,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_IMPRESSION,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.TEXT]: {
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
  },
  [LINKEDIN_CAMPAIGN_OBJECTIVE_TYPE.WEBSITE_VISIT]: {
    [LINKEDIN_AD_FORMAT_TYPE.IMAGE]: {
      [LINKEDIN_OPTIMIZATION_GOAL.LANDING_PAGE_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_CLICKS,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.CAROUSEL]: {
      [LINKEDIN_OPTIMIZATION_GOAL.LANDING_PAGE_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_CLICKS,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.VIDEO]: {
      [LINKEDIN_OPTIMIZATION_GOAL.LANDING_PAGE_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_CLICKS,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.TEXT]: {
      [LINKEDIN_OPTIMIZATION_GOAL.LANDING_PAGE_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
  },
  [LINKEDIN_CAMPAIGN_OBJECTIVE_TYPE.ENGAGEMENT]: {
    [LINKEDIN_AD_FORMAT_TYPE.IMAGE]: {
      [LINKEDIN_OPTIMIZATION_GOAL.ENGAGEMENT_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_CLICK,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.CAROUSEL]: {
      [LINKEDIN_OPTIMIZATION_GOAL.ENGAGEMENT_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_CLICK,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.VIDEO]: {
      [LINKEDIN_OPTIMIZATION_GOAL.ENGAGEMENT_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_CLICK,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
  },
  [LINKEDIN_CAMPAIGN_OBJECTIVE_TYPE.LEAD_GENERATION]: {
    [LINKEDIN_AD_FORMAT_TYPE.IMAGE]: {
      [LINKEDIN_OPTIMIZATION_GOAL.LEADS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_LEAD,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_LEADS,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.LANDING_PAGE_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.CAROUSEL]: {
      [LINKEDIN_OPTIMIZATION_GOAL.LEADS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_LEAD,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_LEADS,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.LANDING_PAGE_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
    [LINKEDIN_AD_FORMAT_TYPE.VIDEO]: {
      [LINKEDIN_OPTIMIZATION_GOAL.LEADS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_LEAD,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
        {
          targetType:
            LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_LEADS,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.LANDING_PAGE_CLICKS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPC,
        },
      ],
      [LINKEDIN_OPTIMIZATION_GOAL.IMPRESSIONS]: [
        {
          targetType: LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE,
          costType: LINKEDIN_COST_TYPE.CPM,
        },
      ],
    },
  },
};

export const OPTIMIZATION_TARGET_TYPE_TO_LABEL_MAPPING = {
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.NONE]: "Manual bidding",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.ENHANCED_CONVERSION]: "Manual bidding",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_REACH]: "Maximum delivery",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_IMPRESSIONS]: "Maximum delivery",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK]: "Maximum delivery",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_LEAD]: "Maximum delivery",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CONVERSION]: "Maximum delivery",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_IMPRESSION]: "Cost cap",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.TARGET_COST_PER_CLICK]: "Cost cap",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_CLICKS]: "Cost cap",
  [LINKEDIN_OPTIMIZATION_TARGET_TYPE.CAP_COST_AND_MAXIMIZE_LEADS]: "Cost cap",
};

export const COST_CAP_DISABLED = [
  LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_REACH,
  LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_IMPRESSIONS,
  LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CLICK,
  LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_LEAD,
  LINKEDIN_OPTIMIZATION_TARGET_TYPE.MAX_CONVERSION,
];
