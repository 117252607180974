import CustomFormControl from "@/components/Form/FormControl/CustomFormControl";
import { formFieldError, InputFieldType, Svg } from "@/types";
import labelRequired from "@/utils/labelRequired";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/system";

import * as React from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";
import { secondaryStyles } from "../secondaryStyles";

export default function CustomInput<T extends FieldValues>({
  label = null,
  placeholder,
  icon,
  position = "start",
  type = "primary",
  control,
  name,
  required,
  disabled = false,
  inputType = "text",
  error,
  setFiles,
  multilineProps = {},
  styles,
  defaultVal = "",
  rules = {},
  toolTip = null,
  popOver,
  popOverIcon,
  ...rest
}: CustomInputProps<T>): ReturnType<React.FC> {
  const theme = useTheme();
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
    rules,
  });
  let localError = undefined;
  const split = name.split(".");
  if (!error && errors && split.length > 0) {
    localError = split?.reduce((acc, val) => acc?.[val] as any, errors);
  }
  const handleOnChange = (event: any) => {
    if (inputType === "file") setFiles?.(event?.target?.files);
    field.onChange(event.target.value);
  };

  const numberInputOnWheelPreventChange = (e: any) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  if (!control) return <div>loading</div>;
  return (
    <CustomFormControl
      label={labelRequired(label, required)}
      error={error ?? localError?.message}
      disabled={disabled}
      toolTip={toolTip}
      popOver={popOver}
      popOverIcon={popOverIcon}
    >
      <OutlinedInput
        sx={{ ...secondaryStyles(type, theme), ...styles }}
        startAdornment={icon && position === "start" ? icon : null}
        endAdornment={icon && position === "end" ? icon : null}
        placeholder={placeholder}
        disabled={disabled}
        {...multilineProps}
        type={inputType}
        {...field}
        onChange={handleOnChange}
        {...(inputType === "number"
          ? {
              inputProps: { inputMode: "numeric", pattern: "[0-9]*" },
              onWheel: numberInputOnWheelPreventChange,
            }
          : {})}
        {...rest}
      />
    </CustomFormControl>
  );
}
type InputType<T extends FieldValues = FieldValues> = Omit<
  OutlinedInputProps,
  "error"
> &
  UseControllerProps<T>;

export interface CustomInputProps<T extends FieldValues = FieldValues>
  extends InputType<T> {
  label?: string | null;
  placeholder?: string;
  icon?: JSX.Element;
  position?: string;
  type?: InputFieldType;
  required?: boolean;
  disabled?: boolean;
  error?: formFieldError;
  setFiles?: React.Dispatch<React.SetStateAction<Array<any>>>;
  inputType?: "text" | "password" | "number" | "file" | "email";
  defaultVal?: string;
  multilineProps?: Record<string, string | number | boolean>;
  styles?: any;
  toolTip?: string | null;
  popOver?: React.ReactNode | null;
  popOverIcon?: Svg | null;
}
