import routes from "@/routes";
import { lazy } from "react";
const CompanyInsight = lazy(
  () => import("@/modules/VisitorInsight/CompanyInsight")
);
const ListCompanyInsight = lazy(
  () => import("@/modules/VisitorInsight/CompanyInsight/ListInsight")
);
const CompanyInsightDetails = lazy(
  () => import("@/modules/VisitorInsight/CompanyInsight/Detail/InsightDetails")
);
const CompanyDetails = lazy(
  () => import("@/modules/VisitorInsight/CompanyInsight/Detail/CompanyDetails")
);
const CompanyInsightRoutes = {
  element: <CompanyInsight />,
  path: routes.VISITOR_INSIGHTS.COMPANY.INDEX,
  children: [
    {
      element: <ListCompanyInsight />,
      path: "",
      children: [
        { element: <CompanyInsightDetails />, path: "" },
        {
          element: <CompanyDetails />,
          path: routes.VISITOR_INSIGHTS.COMPANY.DETAIL(),
        },
      ],
    },
  ],
};

export default CompanyInsightRoutes;
