export enum HEY_REACH_TYPE {
  LEAD = "lead",
  CAMPAIGN = "campaign",
}
export const HeyReachType = [
  {
    label: "Lead List",
    value: HEY_REACH_TYPE.LEAD,
  },
  {
    label: "Campaign",
    value: HEY_REACH_TYPE.CAMPAIGN,
  },
];
