import { formFieldError } from "@/types";
import { Box, Button, FormHelperText, Typography } from "@mui/material";
import * as React from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";

export default function CustomFileInput<T extends FieldValues>({
  control,
  name,
  error,
  inputProps = {},
}: CustomFileInputProps<T>): ReturnType<React.FC> {
  const { field } = useController({
    control,
    name,
  });
  const handleOnChange = (e: React.ChangeEvent) => {
    field.onChange((e?.target as HTMLInputElement)?.files?.[0]);
  };
  return (
    <Box sx={{ py: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
        <Typography variant="body2Semi" color="secondary.light">
          Choose File
        </Typography>
        <input
          type="file"
          id={`fileUpload-${name}`}
          onChange={handleOnChange}
          style={{ display: "none" }}
          {...inputProps}
        />
        <Button
          component="label"
          htmlFor={`fileUpload-${name}`}
          variant="custom1"
        >
          Select File
        </Button>
        <Typography>{field?.value?.name}</Typography>
      </Box>
      <Box>{error && <FormHelperText error>{`${error}`}</FormHelperText>}</Box>
    </Box>
  );
}
interface CustomFileInputProps<T extends FieldValues = FieldValues>
  extends UseControllerProps<T> {
  error?: formFieldError;
  inputProps?: React.HTMLProps<HTMLInputElement>;
}
