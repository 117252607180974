import React, { createContext, useState } from "react";

// Define the shape of the context value
interface FilterContextType {
  filters: Record<string, object>;
  updateFilter: (page: string, newFilter: object) => void;
  getFiltersForPath: (pathname: string) => object;
}

// Create a Context for filters with a default value
const FilterContext = createContext<FilterContextType>({
  filters: {}, // Default empty filters object
  updateFilter: () => undefined, // Default no-op function
  getFiltersForPath: () => ({}), // Default no-op function
});

export function useFilterContext() {
  const context = React.useContext(FilterContext);
  if (!context) {
    throw new Error("useFilterContext can only be used inside FilterProvider");
  }
  return context;
}

export const FilterProvider = ({ children }: { children: React.ReactNode }) => {
  // Local state to store filters
  const [filters, setFilters] = useState<Record<string, object>>({});
  // Function to update filters
  const updateFilter = (page: string, newFilter: object) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [page]: newFilter,
    }));
  };
  const getFiltersForPath = (pathname: string) => {
    return filters[pathname] || {};
  };

  return (
    <FilterContext.Provider
      value={{ filters, updateFilter, getFiltersForPath }}
    >
      {children}
    </FilterContext.Provider>
  );
};
