import SpacingGrid from "@/components/Grid/SpacingGrid";
import { Typography } from "@mui/material";

export default function QuotaPopOver({
  quota,
  quotaLoading,
}: QuotaPopOverProps) {
  return (
    <SpacingGrid spacingType="form">
      <SpacingGrid item>
        <Typography variant="body1Bold">
          Total / Remaining Monthly Quota
        </Typography>
      </SpacingGrid>
      <SpacingGrid item>
        <Typography>
          {!quotaLoading &&
            Object.keys(quota ?? {}).length &&
            `${quota.total} / ${quota.total - quota.usage}`}
        </Typography>
      </SpacingGrid>
    </SpacingGrid>
  );
}

interface QuotaPopOverProps {
  quota: any;
  quotaLoading: boolean;
}
