import * as React from "react";
export type CustomPagination = ReturnType<typeof usePagination>;
export default function usePagination({
  defaultRowPerPage = 10,
}: IUsePaginationProps = {}) {
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowPerPage] =
    React.useState<number>(defaultRowPerPage);

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) {
    setPage(newPage);
  }
  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowPerPage(parseInt(event.target.value));
    setPage(0);
  }
  function handleResetPagination() {
    setPage(0);
    setRowPerPage(defaultRowPerPage);
  }
  return {
    page,
    setPage,
    rowsPerPage,
    setRowPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleResetPagination,
  };
}

interface IUsePaginationProps {
  defaultPage?: number;
  defaultRowPerPage?: number;
}
