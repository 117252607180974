import { MODULES, USER_TYPE } from "@/constants";
import { IPermissions, IUser } from "@/modules/User/user.interface";
import * as React from "react";
import AccountInfoRoutes from "./dashboard/accountInfo.routes";
import AdvertiserRoutes from "./dashboard/advertiser.routes";
import AssetRoutes from "./dashboard/asset.routes";
import AudienceRoutes from "./dashboard/audience.routes";
import CampaignRoutes from "./dashboard/campaign.routes";
import ReportsCampaignSummaryRoutes from "./dashboard/campaignSummaryReports.routes";
import ContactRoutes from "./dashboard/contact.routes";
import ConversionRoutes from "./dashboard/conversion.routes";
import CustomListRoutes from "./dashboard/customList.routes";
import EmailOutreachRoutes from "./dashboard/emailOutreach.routes";
import HomeRoutes from "./dashboard/home.routes";
import VisitorInsightRoutes from "./dashboard/insight.routes";
import IntegrationRoutes from "./dashboard/integration.routes";
import LeadRoutes from "./dashboard/leadGen.routes";
import PresetRoutes from "./dashboard/preset.routes";
import ReportRoutes from "./dashboard/reports.routes";
import { default as SetupRoutes } from "./dashboard/setup.routes";
import PixelRoutes from "./dashboard/siteVisiter.routes";
import TemplateRoutes from "./dashboard/template.routes";
import UserRoutes from "./dashboard/user.routes";
import UtmRoutes from "./dashboard/utm.routes";
import LinkedInEngagementRoutes from "./dashboard/linkedInEngagement.routes";
const MainLayout = React.lazy(() => import("@/layouts/MainLayout"));

export const MainRoutes = (user: IUser) => {
  const permission = user?.Role?.$permissions;
  if (!user) return [];
  return [
    {
      element: <MainLayout />,
      path: "dashboard",
      children: permittedRoutes(
        permission,
        user?.Role?.name === USER_TYPE.SUPER_ADMIN
      ),
    },
  ];
};

function permittedRoutes(permission: IPermissions, isAdmin: boolean) {
  if (isAdmin) return ADMIN_ROUTES;
  const routes = [];
  if (permission[MODULES.DASHBOARD]) {
    routes.push(HomeRoutes);
  }
  routes.push(AccountInfoRoutes);
  routes.push(ReportsCampaignSummaryRoutes);
  if (
    permission[MODULES.INTEGRATIONS_BEESWAX] ||
    permission[MODULES.INTEGRATIONS_LINKEDIN] ||
    permission[MODULES.INTEGRATIONS_FACEBOOK] ||
    permission[MODULES.INTEGRATIONS_HEYREACH] ||
    permission[MODULES.INTEGRATIONS_SMARTLEAD] ||
    permission[MODULES.INTEGRATIONS_DISPLAYVIDEO360] ||
    permission[MODULES.INTEGRATIONS_TRADEDESK] ||
    permission[MODULES.INTEGRATIONS_SALESFORCE] ||
    permission[MODULES.INTEGRATIONS_HUBSPOT]
  ) {
    routes.push(IntegrationRoutes);
  }
  if (permission[MODULES.ADVERTISERS]) {
    routes.push(AdvertiserRoutes);
  }
  if (permission[MODULES.CAMPAIGNS] || permission[MODULES.QUICK_CAMPAIGNS]) {
    routes.push(CampaignRoutes(permission, isAdmin));
  }
  if (
    permission[MODULES.AUDIENCE_ACCOUNT] ||
    permission[MODULES.AUDIENCE_EMAIL] ||
    permission[MODULES.AUDIENCE_SITE_VISITOR] ||
    permission[MODULES.AUDIENCE_MANAGER]
  ) {
    routes.push(AudienceRoutes(permission, isAdmin));
  }
  if (permission[MODULES.CREATIVES]) {
    routes.push(AssetRoutes);
  }
  if (permission[MODULES.CREATIVE_TEMPLATES]) {
    routes.push(TemplateRoutes);
  }
  if (permission[MODULES.FORMS]) {
    routes.push(LeadRoutes);
  }
  if (permission[MODULES.UTMS]) {
    routes.push(UtmRoutes);
  }
  if (permission[MODULES.TAGS]) {
    routes.push(PixelRoutes);
  }
  if (permission[MODULES.CONVERSIONS]) {
    routes.push(ConversionRoutes);
  }
  if (
    permission[MODULES.COMPANY_VISITOR_INSIGHTS] ||
    permission[MODULES.PROFILE_VISITOR_INSIGHTS]
  ) {
    routes.push(VisitorInsightRoutes(permission));
  }
  if (permission[MODULES.CONTACT_BI]) {
    routes.push(ContactRoutes);
  }
  if (
    permission[MODULES.CAMPAIGN_STATS] ||
    permission[MODULES.ABM_STATS] ||
    permission[MODULES.REPORTS_SETUP] ||
    permission[MODULES.ATTRIBUTION]
  ) {
    routes.push(ReportRoutes(permission, isAdmin));
  }
  if (
    permission[MODULES.AGENCY_USERS] ||
    permission[MODULES.ADVERTISER_USERS]
  ) {
    routes.push(UserRoutes(permission, isAdmin));
  }
  if (permission[MODULES.TARGETING_EXPRESSION_PRESET]) {
    routes.push(PresetRoutes(permission, false));
    routes.push(CustomListRoutes);
  }

  routes.push(EmailOutreachRoutes(permission)); // add permission check
  return routes;
}

export const ADMIN_ROUTES = [
  AdvertiserRoutes,
  AudienceRoutes({}, true),
  SetupRoutes,
  AssetRoutes,
  LeadRoutes,
  UtmRoutes,
  PixelRoutes,
  ConversionRoutes,
  LinkedInEngagementRoutes
];
