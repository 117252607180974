import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import { FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import usePermission from "@/hooks/usePermission";
import { messages } from "@/messages";
import { TName } from "@/types";
import { Grid, Tooltip } from "@mui/material";
import { Control, useWatch } from "react-hook-form";
import {
  SPECIFICIDS,
  filterGlobalKey,
  isRenderComponent,
  scopeValues,
} from "../data";
import {
  CommonFieldComponentProps,
  ComponentData,
  ExtraField,
  NodeComponentProps,
  ScopeList,
} from "../user.interface";

export default function ModuleFilesAccessPermission(
  props: CommonFieldComponentProps
) {
  const { componentData, permissions, createUserType, isEdit = false } = props;

  return (
    <Grid item xs={12}>
      {componentData.map((x: ComponentData, i: number) => {
        if (
          isRenderComponent({ data: x, permissions, createUserType, isEdit })
        ) {
          return <Component key={i} {...props} data={x} />;
        }
      })}
    </Grid>
  );
}

function Component({
  control,
  errors,
  permissions,
  data,
  isEdit,
  isAgencyAdminCreationOrEdit,
}: NodeComponentProps) {
  let inaccessibleFilesByEditor = [];
  const watched = useWatch({
    name: data.dependent as TName<any>,
    control,
    disabled: !data.dependent,
  });
  const componentValue = useWatch({
    name: [data.name as TName<any>, `${data.name}${SPECIFICIDS}`],
    control,
    disabled: !isEdit,
  });
  const { permission: creatorOrEditorPermissions } = usePermission();
  let isDisable = false;
  if (isEdit) {
    const editorsModulePermission = creatorOrEditorPermissions[data.permission];
    if (
      editorsModulePermission &&
      componentValue[0] &&
      componentValue[0] !== "none"
    ) {
      const ids = filterGlobalKey(editorsModulePermission);
      if (editorsModulePermission.global.length < componentValue[0].length)
        isDisable = true;
      else if (ids?.length) {
        if (data.fileData?.length) {
          inaccessibleFilesByEditor = data.fileData
            .filter(
              (file: { label: string; value: number }) =>
                !ids.includes(String(file.value))
            )
            ?.map((file: { label: string; value: number }) => file.value);
        }
      }
    }
  }
  const scopeItemList = scopeValues(
    permissions[data.permission]?.global,
    data.valueToFilter?.(isAgencyAdminCreationOrEdit),
    data.bypassPermission,
    data.scopeFilter
  );
  const mutatedScopeItemList =
    data?.labelModifier?.(scopeItemList as ScopeList) ?? scopeItemList;
  return (
    <Tooltip title={isDisable && messages.notAuthorized} placement="top">
      <Grid
        item
        container
        justifyContent="center"
        columnSpacing={FORM_COLUMN_SPACING}
        rowSpacing={FORM_ROW_SPACING}
        xs={12}
      >
        <Grid item xs={12}>
          <CustomAutocomplete
            name={data.name}
            placeholder={data?.placeholders?.[0] ?? "Select Access Level"}
            control={control}
            itemList={mutatedScopeItemList}
            error={errors?.[data.name]?.message}
            type="secondary"
            disabled={isDisable}
          />
        </Grid>
        {data?.extraFields?.length && (
          <>
            {" "}
            {data.extraFields.map((field, index) => {
              if (!field.bypassPermission && !permissions[field.permission])
                return null;
              return (
                <ExtraFieldComponent
                  key={index}
                  field={field}
                  control={control}
                  errors={errors}
                  isDisable={isDisable}
                />
              );
            })}
          </>
        )}
        <Grid item xs={12}>
          <CustomAutocomplete
            name={data.fileFieldName ?? `${data.name}${SPECIFICIDS}`}
            placeholder={data?.placeholders?.[1] ?? "Files"}
            control={control}
            itemList={data.fileData ?? []}
            error={
              errors?.[data.fileFieldName ?? `${data.name}${SPECIFICIDS}`]
                ?.message
            }
            type="secondary"
            disabled={isDisable || data?.dependentCondition?.(watched)}
            isMultiple
          />
        </Grid>
      </Grid>
    </Tooltip>
  );
}

function ExtraFieldComponent({
  field,
  control,
  errors,
  isDisable,
}: {
  field: ExtraField;
  control: Control<any>;
  errors: any;
  isDisable: boolean;
}) {
  const watched = useWatch({
    name: field.dependent as TName<any>,
    control,
    disabled: !field.dependent,
  });
  return (
    <Grid item xs={12}>
      <CustomAutocomplete
        name={field.name}
        placeholder={field?.placeholder}
        control={control}
        itemList={field.fileData}
        error={errors?.[field.name]?.message}
        type="secondary"
        disabled={isDisable || field.dependentCondition?.(watched)}
        {...field.componentProps}
      />
    </Grid>
  );
}
