export const displayMatchTypeList = [
  { label: "Exact", value: "exact" },
  { label: "Prefix", value: "prefix" },
  { label: "Keyword", value: "keyword" },
  { label: "Domain", value: "domain" },
];
export const RETARGET_BY = {
  WEBSITE_RETARGETING_KNOWN: "websiteRetargetingKnown",
  WEBSITE_RETARGETING_UN_KNOWN: "websiteRetargetingUnKnown",
};
export type RETARGET_BY_TYPE = (typeof RETARGET_BY)[keyof typeof RETARGET_BY];
export const RETARGET_BY_VALUES = [
  {
    label: "Visitor Insight",
    value: RETARGET_BY.WEBSITE_RETARGETING_KNOWN,
  },
  {
    label: "General Retargeting",
    value: RETARGET_BY.WEBSITE_RETARGETING_UN_KNOWN,
  },
];
