import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export const getSiteVisiterList = async <T>(
  data: QueryFunctionContext
): Promise<T> => {
  return client.get(
    appendQueryParams("/site-visitors", data.meta?.queryParams ?? {})
  );
};
export const getDisplaySiteVisiterList = async (data: QueryFunctionContext) => {
  return client.get(
    appendQueryParams("/site-visitors/display", data.meta?.queryParams ?? {})
  );
};
export function createSiteVister(payload: any) {
  return client.post("/site-visitors", payload);
}
export function editSiteVister({ id, payload }: { id: any; payload: any }) {
  return client.put(`/site-visitors/${id}`, payload);
}

export function uploadLinkedinEngagemantFile({ payload }: { payload: any }) {
  return client.post(`/linkedinEngagement/upload-file`, payload);
}
