import { BORDER_RADIUS, BOX_SHADOW } from "@/constants";
import { CSSObject, styled } from "@mui/material";
import { MUIStyledCommonProps } from "@mui/system";

import { ICardContainer } from "@/components/Card/card.interface";
import * as React from "react";

interface StyledDiv extends MUIStyledCommonProps {
  secondaryColor?: boolean;
  shadow?: boolean;
  customHeight?: string;
}

const StyledDiv = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "secondaryColor" && prop !== "shadow" && prop !== "customHeight",
})<StyledDiv>(
  ({ theme, secondaryColor, shadow, customHeight }): CSSObject => ({
    width: "100%",
    height: customHeight ?? "100%",
    backgroundColor: secondaryColor
      ? theme.palette.background.default
      : theme.palette.background.paper,
    display: "flex",
    borderRadius: BORDER_RADIUS,
    flexDirection: "column",
    boxShadow: shadow ? BOX_SHADOW : "none",
  })
);

export default function CardContainer({
  children,
  secondaryColor = false,
  sx,
  shadow = true,
  customHeight,
}: CardContainerProps): ReturnType<React.FC> {
  return (
    <StyledDiv
      secondaryColor={secondaryColor}
      sx={sx}
      shadow={shadow}
      customHeight={customHeight}
    >
      {children}
    </StyledDiv>
  );
}

type CardContainerProps = ICardContainer;
