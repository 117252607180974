import CustomIconPopover from "@/components/Popover/CustomIconPopover";
import { formFieldError, Svg } from "@/types";
import labelRequired from "@/utils/labelRequired";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import * as React from "react";
import { ExtraButtonsWithInputLabel } from "../../InputFields/inputfields.interface";

export default function CustomFormControl({
  label = null,
  children,
  required = false,
  disabled = false,
  error,
  toolTip = null,
  extraButtons,
  popOver = null,
  popOverIcon = null,
}: CustomFormControlProps): ReturnType<React.FC> {
  return (
    <FormControl fullWidth disabled={disabled}>
      {label && (
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {labelRequired(label, required)}
          <Box sx={{ display: "flex" }}>
            {toolTip && (
              <Tooltip title={toolTip} placement="top-start">
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            {popOver && <CustomIconPopover icon={popOverIcon}>{popOver}</CustomIconPopover>}
            {extraButtons?.length &&
              extraButtons.map((buttonProps, index) => {
                const Icon = buttonProps.icon;
                return (
                  <Tooltip
                    key={index}
                    title={buttonProps.toolTipText}
                    placement="top-start"
                  >
                    <IconButton onClick={buttonProps.handleOnClick}>
                      <Icon />
                    </IconButton>
                  </Tooltip>
                );
              })}
          </Box>
        </InputLabel>
      )}
      {children}
      {error && <FormHelperText error>{`${error}`}</FormHelperText>}
    </FormControl>
  );
}

export interface CustomFormControlProps {
  label?: string | null;
  children: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  error?: formFieldError;
  toolTip?: string | null;
  popOver?: React.ReactNode | null;
  popOverIcon?: Svg | null;
  extraButtons?: ExtraButtonsWithInputLabel[];
}
