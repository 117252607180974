import { checkPermission } from "@/components/Guard/PermissionGuard";
import { MODULES } from "@/constants";
import { IPermissions } from "@/modules/User/user.interface";
import routes from "@/routes";
import { lazy } from "react";
const EmailOutreach = lazy(() => import("@/modules/EmailOutreach"));
const EmailOutreachList = lazy(() => import("@/modules/EmailOutreach/List"));
const CreateOrEditEmailOutreach = lazy(
  () => import("@/modules/EmailOutreach/CreateOrEdit")
);

const EmailOutreachRoutes = (permission: IPermissions) => {
  const children = [];
  const moduleArray = [MODULES.AUDIENCE_EMAIL];
  if (moduleArray.some((x) => checkPermission(permission[x]?.global, "read"))) {
    children.push({
      element: <EmailOutreachList />,
      path: "",
    });
  }
  if (
    moduleArray.some((x) => checkPermission(permission[x]?.global, "create"))
  ) {
    children.push({
      element: <CreateOrEditEmailOutreach />,
      path: routes.EMAIL_OUTREACH.CREATE,
    });
  }
  if (
    moduleArray.some((x) => checkPermission(permission[x]?.global, "update"))
  ) {
    children.push({
      element: <CreateOrEditEmailOutreach isEdit />,
      path: routes.EMAIL_OUTREACH.EDIT(),
    });
  }

  return {
    element: <EmailOutreach />,
    path: routes.EMAIL_OUTREACH.INDEX,
    children: children,
  };
};

export default EmailOutreachRoutes;
