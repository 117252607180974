import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export function getEngagementType(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/linkedin/engagementTypes`, data.meta?.queryParams ?? {})
  );
}
export function getEngagementTimeSpans(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `/linkedin/engagementTimeSpans`,
      data.meta?.queryParams ?? {}
    )
  );
}
