import routes from "@/routes";
import { lazy } from "react";
const BeeswaxCustomList = lazy(() => import("@/modules/BeeswaxList"));
const BwCustomListTable = lazy(() => import("@/modules/BeeswaxList/List"));

const CustomListRoutes = {
  element: <BeeswaxCustomList />,
  path: routes.CUSTOM_LISTS.INDEX,
  children: [
    {
      element: <BwCustomListTable />,
      path: "",
    },
  ],
};

export default CustomListRoutes;
