/*

** this helpers is used to place general methods to perform
 common util tasks **
 
 */

import convertRGBACSSToRGBAObject from "./convertRGBACSSToRGBAObject";

// *** get deuration of a video by passing file type ***
export const getVideoDuration = async (file: any) => {
  const url = URL.createObjectURL(file);

  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.muted = true;
    const source = document.createElement("source");
    source.src = url; //--> blob URL
    video.preload = "metadata";
    video.appendChild(source);
    video.onloadedmetadata = function () {
      resolve(video.duration);
    };
  });
};

export const formateDataForCustomSelect = (
  data: any,
  labelKey: string,
  valueKey: string
) => {
  return data?.map((item: any) => ({
    label: item[labelKey],
    value: item[valueKey],
  }));
};
