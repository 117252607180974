import { URL_REGEX } from "@/constraints";
import * as yup from "yup";
import {
  AdvertiserSwitchState,
  SmartleadAdvertiserCompanyAssociationType,
} from "./advertiser.interface";
import { MODULES, USER_KEY } from "@/constants";
import { messages } from "@/messages";
import { SMARTLEAD_ADVERTISER_COMPANY_ASSOCIATION_TYPE } from "./advertiser.constant";

type ValidationKeys = keyof typeof validation;

const cyclicDependencyArray = {
  linkedin: ["linkedinAdAccountId", "linkedinPageId"],
};
const validation = {
  display: {
    domain: yup
      .string()
      .matches(URL_REGEX, "Invalid URL")
      .nullable(true)
      .required(),
  },
  linkedin: {
    canConnectLinkedin: yup.mixed<any>().oneOf([0, 1]).required().nullable(),
    linkedinCurrency: yup.string().when("canConnectLinkedin", {
      is: linkedinAccountCheck,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    linkedinPageId: yup
      .number()
      .nullable()
      .when("canConnectLinkedin", {
        is: linkedinAccountCheck,
        then: yup.number().when("linkedinAdAccountId", {
          is: (val: number | null) => !val || val === 0,
          then: yup.number().min(1).nullable().required(),
          otherwise: yup.number().nullable(),
        }),
        otherwise: yup.number().nullable(),
      }),
    linkedinAdAccountId: yup.number().when("canConnectLinkedin", {
      is: linkedinAccountCheck,
      then: yup.number().when("linkedinPageId", {
        is: (val: number | null) => !val || val === 0,
        then: yup.number().min(1).nullable().required(),
        otherwise: yup.number().nullable(),
      }),
      otherwise: yup.number().nullable(),
    }),
  },
  facebook: {
    fbAdAccountId: yup.string().nullable(true).required(),
    fbPageId: yup.string().nullable(true).required(),
  },
  heyreach: {},
  smartlead: {
    // twice same keys is added because it is an object in default values
    smartlead: yup.object({
      companyAssociationType: yup
        .string()
        .oneOf(Object.values(SMARTLEAD_ADVERTISER_COMPANY_ASSOCIATION_TYPE))
        .required(messages.requiredField)
        .nullable(),
      name: yup.string().when("companyAssociationType", {
        is: (
          companyAssociationType: SmartleadAdvertiserCompanyAssociationType
        ) =>
          companyAssociationType ===
          SMARTLEAD_ADVERTISER_COMPANY_ASSOCIATION_TYPE.NEW_ACCOUNT,
        then: yup.string().required(messages.requiredField),
        otherwise: yup.string().notRequired(),
      }),
      email: yup.string().when("companyAssociationType", {
        is: (
          companyAssociationType: SmartleadAdvertiserCompanyAssociationType
        ) =>
          companyAssociationType ===
          SMARTLEAD_ADVERTISER_COMPANY_ASSOCIATION_TYPE.NEW_ACCOUNT,
        then: yup.string().email().required(messages.requiredField),
        otherwise: yup.string().notRequired(),
      }),
      importedClientId: yup.number().when("companyAssociationType", {
        is: (
          companyAssociationType: SmartleadAdvertiserCompanyAssociationType
        ) =>
          companyAssociationType ===
          SMARTLEAD_ADVERTISER_COMPANY_ASSOCIATION_TYPE.IMPORTED_ACCOUNT,
        then: yup.number().required(messages.requiredField).nullable(),
        otherwise: yup.number().notRequired().nullable(),
      }),
    }),
  },
};

export function validationSchema({
  channel,
  type,
}: {
  channel: AdvertiserSwitchState;
  type: "edit" | "create";
}) {
  const dependencyArray: any[] = [];
  let schema = {
    name: yup.string().required("Required"),
  };
  Object.keys(channel).forEach((x) => {
    if (channel[x as ValidationKeys]) {
      schema = {
        ...schema,
        ...validation[x as ValidationKeys],
      };
      if (cyclicDependencyArray[x as keyof typeof cyclicDependencyArray]) {
        dependencyArray.push(
          ...cyclicDependencyArray[x as keyof typeof cyclicDependencyArray]
        );
      }
    }
  });

  return yup.object().shape(
    {
      ...schema,
    },
    dependencyArray
  );
}

function linkedinAccountCheck(val: number | null) {
  return !val;
}

export function filterDataAccordingToAllowedAdvertisers(
  key: any,
  keepOwnCompanyId = false
) {
  const stringifiedUser = window.localStorage.getItem(USER_KEY);
  const user: any = stringifiedUser ? JSON.parse(stringifiedUser) : null;

  const USER_ADVERTISERS_LIST = (
    user.Role.$permissions[MODULES.ADVERTISERS]
      ? Object.keys(user.Role.$permissions[MODULES.ADVERTISERS])
      : []
  )
    .filter((item) => item !== "global")
    .map(Number);

  if (keepOwnCompanyId && USER_ADVERTISERS_LIST.length) {
    USER_ADVERTISERS_LIST.push(user.companyId); // this is actually the agency id, violating the name, but who cares
  }

  function filterFn(item: any) {
    return USER_ADVERTISERS_LIST.length
      ? USER_ADVERTISERS_LIST.includes(item[key])
      : true;
  }

  return (data: any) => {
    if (Array.isArray(data)) {
      return data.filter(filterFn);
    } else if (Array.isArray(data.rows)) {
      data.rows = data.rows.filter(filterFn);
      return data;
    } else {
      return data;
    }
  };
}
