import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { AccountPage } from ".";

export default function AccountDetail({
  data,
  setActivePage,
}: AccountDetailProps) {
  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3Semi">Account Information</Typography>
      </Box>
      <Box>
        <AccountInfoTable rows={data} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CustomLoadingButton
          title="Change Password"
          isLoading={false}
          onClick={() => setActivePage("Form")}
        />
      </Box>
    </>
  );
}

type AccountData = { label: string; value: string | number }[];
interface AccountDetailProps {
  data: AccountData;
  setActivePage: React.Dispatch<React.SetStateAction<AccountPage>>;
}

function AccountInfoTable({ rows }: { rows: AccountData }) {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="Account Info">
      <TableBody>
        {rows.map((row) =>
          row?.value ? (
            <TableRow
              key={row.label}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">:</TableCell>
              <TableCell align="right">
                {row.value === Number.POSITIVE_INFINITY
                  ? "Unlimited"
                  : row.value}
              </TableCell>
            </TableRow>
          ) : null
        )}
      </TableBody>
    </Table>
  );
}
