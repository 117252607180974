import routes from "@/routes";
import { lazy } from "react";
import GeneralCampaignRoutes from "./generalCampaign.routes";
import QuickCampaignRoutes from "./quickCampaign.routes";
import { IPermissions } from "@/modules/User/user.interface";
import { MODULES } from "@/constants";
const Campaign = lazy(() => import("@/modules/Campaign"));

const CampaignRoutes = (permission: IPermissions, isAdmin: boolean) => {
  const children = [];
  if (isAdmin || permission[MODULES.CAMPAIGNS]) {
    children.push(GeneralCampaignRoutes);
  }
  if (isAdmin || permission[MODULES.QUICK_CAMPAIGNS]) {
    children.push(QuickCampaignRoutes);
  }

  return {
    element: <Campaign />,
    path: routes.CAMPAIGNS.INDEX,
    children: children,
  };
};

export default CampaignRoutes;
