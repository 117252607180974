import { ReactComponent as DisplayLogo } from "@/assets/dashboard/common/display.svg";
import CardExpandable from "@/components/Card/CardExpandable";
import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import CustomSelect from "@/components/Form/InputFields/CustomSelect";
import { CHANNELS, FORM_COLUMN_SPACING, FORM_ROW_SPACING } from "@/constants";
import { regions } from "@/modules/Audience/data";
import { Box, Grid } from "@mui/material";
const PARENT_NAME = CHANNELS.DISPLAY;
export default function DisplayForm({
  disabled,
  displaySwitch,
  setDisplaySwitch,
  control,
  errors,
  isEdit,
  BeeswaxCampaign,
}: any) {
  return (
    <CardExpandable
      title="DISPLAY"
      Icon={DisplayLogo}
      isChecked={true}
      disabled={disabled}
      check={displaySwitch}
      setCheck={setDisplaySwitch}
      switchLabel={false}
      expandToggleOn="switch"
    >
      <Box sx={{ py: 3 }}>
        <Grid
          container
          columnSpacing={FORM_COLUMN_SPACING}
          rowSpacing={FORM_ROW_SPACING}
        >
          <Grid item xs={12}>
            <CustomAutocomplete
              name={`${PARENT_NAME}.continents`}
              label="Select Continent(s)"
              control={control}
              itemList={regions}
              error={errors?.[PARENT_NAME]?.continents?.message}
              required={true}
              isMultiple={true}
              type="secondary"
              disabled={!displaySwitch || (isEdit && BeeswaxCampaign)}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomSelect
              name={`${PARENT_NAME}.budgetType`}
              label="Budget Type"
              control={control}
              itemList={budgetTypeList}
              error={errors?.[PARENT_NAME]?.budgetType?.message}
              required={true}
              type="secondary"
              disabled={!displaySwitch || (isEdit && BeeswaxCampaign)}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              name={`${PARENT_NAME}.budgetValue`}
              label="Budget Value"
              control={control}
              error={errors?.[PARENT_NAME]?.budgetValue?.message}
              required={true}
              type="secondary"
              disabled={!displaySwitch || (isEdit && BeeswaxCampaign)}
              inputType="number"
            />
          </Grid>
        </Grid>
      </Box>
    </CardExpandable>
  );
}

const budgetTypeList = [
  { label: "Spend", value: 0 },
  { label: "Impressions", value: 1 },
];
