import { FONT_FAMILY } from "@/constants";
import { Theme } from "@mui/material";
import { alpha } from "@mui/material/styles";

const InputBase = (theme: Theme) => ({
  MuiInputBase: {
    styleOverrides: {
      // Name of the slot
      root: {
        padding: "0",
        fontSize: "0.875rem",
        fontFamily: FONT_FAMILY,
        fontWeight: 400,
        border: "none",
        "input:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ntextarea:-webkit-autofill,\ntextarea:-webkit-autofill:hover,\ntextarea:-webkit-autofill:focus,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus":
          {
            WebkitBoxShadow: `0 0 0px 1000px ${
              theme.palette.mode === "light"
                ? theme.palette.background.paper
                : "#2b2b2b"
            } inset !important`,
          },
      },
      input: {
        borderRadius: 4,
        position: "relative",
        fontSize: 16,
        width: "100%",
        padding: "0",
        backgroundColor:
          theme.palette.mode === "light"
            ? theme.palette.background.paper
            : "#2b2b2b",
        textOverflow: "ellipsis",
      },
    },
  },
});

export default InputBase;
