import appendQueryParams from "@/utils/appendQueryParams";
import { QueryFunctionContext } from "@tanstack/react-query";
import client from "../client";

export function getBeeswaxAccounts() {
  return client.get("/beeswax/accounts");
}

export function getBeeswaxAdvertisers(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/advertisers", data.meta?.queryParams ?? {})
  );
}

export function createBeeswaxAccount() {
  return client.post("/beeswax/account");
}

export function getBeeswaxLineItem(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `/beeswax/line_items/${data.meta?.id}`,
      data.meta?.queryParams ?? {}
    )
  );
}
export function getBeeswaxLineItemAudience(data: QueryFunctionContext) {
  return client.get(`/beeswax/line_items/${data.meta?.id}/audiences`);
}
export function createAssets(payload: any) {
  return client.post("/beeswax/creatives", payload);
}
export function getCountries(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/countries", data?.meta?.queryParams ?? {})
  );
}

export function getBeeswaxPresets(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/presets", data?.meta?.queryParams ?? {})
  );
}
export function getBeeswaxPresetsById(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `/beeswax/presets/${data.meta?.id}`,
      data?.meta?.queryParams ?? {}
    )
  );
}
export function createBeeswaxPreset<TDATA extends object>(payload: TDATA) {
  return client.post("/beeswax/presets", payload);
}
export function editBeeswaxPreset<TDATA extends object>({
  id,
  payload,
}: {
  id: string;
  payload: TDATA;
}) {
  return client.put(`/beeswax/presets/${id}`, payload);
}

export function deleteBeeswaxPreset({ id }: { id: number }) {
  return client.delete(`/beeswax/presets/${id}`);
}

export function getBeeswaxThirdPartyDataProvider(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/data-providers", data?.meta?.queryParams ?? {})
  );
}
export function getBeeswaxThirdPartySegmentSearch(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/segment-search", data?.meta?.queryParams ?? {})
  );
}
export function getBeeswaxThirdPartySegmentTree(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/segment-tree", data?.meta?.queryParams ?? {})
  );
}

export function getAllBeeswaxList(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/list/all", data?.meta?.queryParams ?? {})
  );
}

export function getBeeswaxList(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/list", data?.meta?.queryParams ?? {})
  );
}
export function createBeeswaxList<TDATA extends object>(payload: TDATA) {
  return client.post("/beeswax/list", payload);
}

export function updateBeeswaxList<TDATA extends object>({
  id,
  payload,
}: {
  id: number;
  payload: TDATA;
}) {
  return client.put(`/beeswax/list/${id}`, payload);
}

export function getBeeswaxInventorySources(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      "/beeswax/inventory-sources",
      data?.meta?.queryParams ?? {}
    )
  );
}

export function getBeeswaxAdPosition(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/ad-position", data?.meta?.queryParams ?? {})
  );
}
export function getBeeswaxEnvironmentTypes(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      "/beeswax/environment-types",
      data?.meta?.queryParams ?? {}
    )
  );
}

export function getBeeswaxDevices(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/devices", data?.meta?.queryParams ?? {})
  );
}

export function getBeeswaxListItems(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams("/beeswax/list-item", data?.meta?.queryParams ?? {})
  );
}

export function createBulkListItem({ id, data }: { id: number; data: any }) {
  return client.post(`/beeswax/list-item/bulk/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
