import {
  createBeeswaxAccount,
  deleteBeeswaxPreset,
  getBeeswaxAccounts,
  getBeeswaxAdPosition,
  getBeeswaxAdvertisers,
  getBeeswaxDevices,
  getBeeswaxEnvironmentTypes,
  getBeeswaxInventorySources,
  getBeeswaxLineItem,
  getBeeswaxLineItemAudience,
  getBeeswaxPresets,
  getBeeswaxPresetsById,
  getCountries,
} from "@/apis/v1/beeswax";
import { useMutation, useQuery } from "@tanstack/react-query";

export const BEESWAX_ACCOUNTS_LIST_KEY = ["beeswax", "accounts"];
export const BEESWAX_ADVERTISERS_LIST_KEY = ["beeswax", "advertisers"];
export const COUNTRIES_KEY = ["beeswax", "countries"];
export const PRESETS_KEY = (
  type?: "campaign" | "line_item" | "targeting_expression",
  page?: number,
  size?: number
) => ["beeswax", "presets", type, page, size];
export const PRESETS_ID_KEY = (id: string) => ["beeswax", "presets", id];

export const BEESWAX_INVENTORY_SOURCES = ["beeswax", "inventory-sources"];
export const BEESWAX_AD_POSITION = ["beeswax", "ad-position"];
export const BEESWAX_ENVIRONMENT_TYPES = ["beeswax", "environment-types"];
export const BEESWAX_DEVICES = ["beeswax", "devices"];

export function useBeeswaxAccountsList() {
  return useQuery(BEESWAX_ACCOUNTS_LIST_KEY, getBeeswaxAccounts);
}

export function useBeeswaxAdvertisersList(onSuccess?: () => void) {
  return useQuery(BEESWAX_ADVERTISERS_LIST_KEY, getBeeswaxAdvertisers, {
    onSuccess,
  });
}

export function useCreateBeeswaxAccount() {
  return useMutation(createBeeswaxAccount);
}

export function useBeeswaxLineItem({
  id,
  enabled = true,
  onSuccess,
  select,
}: {
  id: any;
  enabled?: boolean;
  onSuccess?: (data: any) => void;
  select?: (data: any) => any;
}) {
  return useQuery(["beeswax", "lineItem", id], getBeeswaxLineItem, {
    enabled,
    onSuccess,
    select,
    meta: {
      id,
    },
  });
}

export function useBeeswaxLineItemAudience({
  id,
  enabled = true,
  onSuccess,
}: {
  id: any;
  enabled?: boolean;
  onSuccess?: (data: any) => void;
}) {
  return useQuery(
    ["beeswax", "lineItem", id, "audience"],
    getBeeswaxLineItemAudience,
    {
      enabled,
      onSuccess,
      meta: {
        id,
      },
    }
  );
}
export function useCountries({
  enabled = true,
  queryParams,
}: {
  enabled?: boolean;
  queryParams?: {
    name: string;
  };
}) {
  return useQuery(COUNTRIES_KEY, getCountries, {
    enabled,
    meta: {
      queryParams,
    },
    staleTime: Infinity,
  });
}

export function useBeeswaxPresets<TDATA>({
  enabled = true,
  queryParams,
  onSuccess,
}: {
  enabled?: boolean;
  queryParams?: {
    type: "campaign" | "line_item" | "targeting_expression";
    page?: number;
    size?: number;
  };
  onSuccess?: (data: any) => void;
}) {
  return useQuery<TDATA>(
    PRESETS_KEY(queryParams?.type, queryParams?.page, queryParams?.size),
    getBeeswaxPresets,
    {
      enabled,
      meta: {
        queryParams,
      },
      onSuccess,
    }
  );
}
export function useBeeswaxPresetById<TDATA>({
  enabled = true,
  queryParams,
  onSuccess,
  id,
}: {
  enabled?: boolean;
  queryParams?: {
    favorite?: boolean;
  };
  id: string;
  onSuccess?: (data: any) => void;
}) {
  return useQuery<TDATA>(PRESETS_ID_KEY(id), getBeeswaxPresetsById, {
    enabled,
    meta: {
      id,
      queryParams,
    },
    onSuccess,
  });
}

export function useBeeswaxInventorySources(args?: {
  queryParams?: {
    id?: number;
    name?: string;
    key?: string;
    category?: string;
    viewable?: boolean;
    page_size?: number;
  };
  options?: Record<any, any>;
}) {
  const { queryParams = {}, options = {} } = args ?? {};
  return useQuery(BEESWAX_INVENTORY_SOURCES, getBeeswaxInventorySources, {
    meta: {
      queryParams: { ...queryParams },
    },
    ...options,
    staleTime: Infinity,
  });
}

export function useBeeswaxAdPosition(args?: {
  queryParams?: {
    id?: number;
    name?: string;
    open_rtb_code?: string;
  };
  options?: Record<any, any>;
}) {
  const { queryParams = {}, options = {} } = args ?? {};
  return useQuery(BEESWAX_AD_POSITION, getBeeswaxAdPosition, {
    meta: {
      queryParams: { ...queryParams },
    },
    ...options,
    staleTime: Infinity,
  });
}

export function useBeeswaxEnvironmentTypes(args?: {
  queryParams?: {
    id?: number;
    name?: string;
  };
  options?: Record<any, any>;
}) {
  const { queryParams = {}, options = {} } = args ?? {};
  return useQuery(BEESWAX_ENVIRONMENT_TYPES, getBeeswaxEnvironmentTypes, {
    meta: {
      queryParams: { ...queryParams },
    },
    ...options,
    staleTime: Infinity,
  });
}

export function useBeeswaxDevices(args?: {
  queryParams?: {
    id?: number;
    name?: string;
  };
  options?: Record<any, any>;
}) {
  const { queryParams = {}, options = {} } = args ?? {};
  return useQuery(BEESWAX_DEVICES, getBeeswaxDevices, {
    meta: {
      queryParams: { ...queryParams },
    },
    ...options,
    staleTime: Infinity,
  });
}

export function useBeeswaxPresetDelete(args?: { options?: Record<any, any> }) {
  return useMutation(deleteBeeswaxPreset, args?.options);
}
