export const ENGEGEMENT_TIME_SPAN = [
  {
    label: "30 days in the past",
    value: 30,
  },
  {
    label: "60 days in the past",
    value: 60,
  },
  {
    label: "90 days in the past",
    value: 90,
  },
  {
    label: "180 days in the past",
    value: 180,
  },
  {
    label: "365 days in the past",
    value: 365,
  },
];
export const facebookMatchTypeList = [
  { label: "Contains", value: "i_contains" },
  { label: "Not Contains", value: "i_not_contains" },
  { label: "Equal", value: "=" },
];
export const RETARGET_BY = {
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  WEBSITE_RETARGETING: "websiteRetargeting",
};
export const RETARGET_BY_ITEMS = [
  { label: "Website retargeting	", value: RETARGET_BY.WEBSITE_RETARGETING },
  { label: "Instagram", value: RETARGET_BY.INSTAGRAM },
  { label: "Facebook", value: RETARGET_BY.FACEBOOK },
];
export enum FACEBOOK_RETARGET_BY_VALUE {
  PAGE_LIKED = "page_liked",
  PAGE_ENGAGED = "page_engaged",
  PAGE_VISITED = "page_visited",
  PAGE_POST_INTERACTION = "page_post_interaction",
  PAGE_CTA_CLICK = "page_cta_clicked",
  PAGE_MESSAGED = "page_messaged",
  PAGE_OR_POST_SAVE = "page_or_post_save",
}
export enum INSTAGRAM_RETARGET_BY_VALUE {
  PROFILE_FOLLOW = "INSTAGRAM_PROFILE_FOLLOW",
  BUISNESS_PROFILE_ALL = "ig_business_profile_all",
  PROFILE_VISIT = "ig_business_profile_visit",
  PROFILE_ENGAGED = "ig_business_profile_engaged",
  USER_MESSAGE = "ig_business_profile_user_messaged",
  PROFILE_AD_SAVED = "ig_business_profile_ad_saved",
}

export const FACEBOOK_RETARGET_BY = [
  {
    label: "Who currently like or follow your page",
    value: FACEBOOK_RETARGET_BY_VALUE.PAGE_LIKED,
  },
  {
    label: "Everyone who engaged with you page",
    value: FACEBOOK_RETARGET_BY_VALUE.PAGE_ENGAGED,
  },
  {
    label: "Anyone who visited your Page",
    value: FACEBOOK_RETARGET_BY_VALUE.PAGE_VISITED,
  },
  {
    label: "who engaged with any post or ad",
    value: FACEBOOK_RETARGET_BY_VALUE.PAGE_ENGAGED,
  },
  {
    label: "who clicked any call-to-action button",
    value: FACEBOOK_RETARGET_BY_VALUE.PAGE_CTA_CLICK,
  },
  {
    label: "who sent a message to your Page",
    value: FACEBOOK_RETARGET_BY_VALUE.PAGE_MESSAGED,
  },
  {
    label: "who saved any post",
    value: FACEBOOK_RETARGET_BY_VALUE.PAGE_OR_POST_SAVE,
  },
];
export const INSTAGRAM_RETARGET_BY = [
  {
    label: "who started following this professional account",
    value: INSTAGRAM_RETARGET_BY_VALUE.PROFILE_FOLLOW,
  },
  {
    label: "Everyone who engaged with this professional account",
    value: INSTAGRAM_RETARGET_BY_VALUE.BUISNESS_PROFILE_ALL,
  },
  {
    label: "Anyone who visited this professional account's profile",
    value: INSTAGRAM_RETARGET_BY_VALUE.PROFILE_VISIT,
  },
  {
    label: "who engaged with any post or ad",
    value: INSTAGRAM_RETARGET_BY_VALUE.PROFILE_ENGAGED,
  },
  {
    label: "People who sent a message to this professional account",
    value: INSTAGRAM_RETARGET_BY_VALUE.USER_MESSAGE,
  },
  {
    label: "who saved any post or ad",
    value: INSTAGRAM_RETARGET_BY_VALUE.PROFILE_AD_SAVED,
  },
];
