import { uploadLinkedinEngagemantFile } from "@/apis/v1/siteVisiter";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CardContainer from "@/components/Card/CardContainer";
import CustomContainer from "@/components/Form/CustomContainer";
import CustomAutocomplete from "@/components/Form/InputFields/CustomAutocomplete";
import CustomFileInput from "@/components/Form/InputFields/CustomFileInput";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { useUserContext } from "@/context/UserContext";
import { messages } from "@/messages";
import { useAdvertisersList } from "@/modules/Advertiser/advertiser.hook";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object({
  advertiserId: yup.number().required(""),
  file: yup.mixed().required(""),
});

export default function LinkedinEngagement(): ReturnType<React.FC> {
  const { loggedInAdvertiserId } = useUserContext();
  const [invalidDataCount, setInvalidDataCount] = React.useState(0);
  const { mutateAsync, isLoading } = useMutation(uploadLinkedinEngagemantFile);
  const { setSnackbarState } = useSnackbarDispatch();
  const {
    control,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<yup.InferType<typeof schema>>({
    defaultValues: {
      advertiserId: loggedInAdvertiserId,
      file: undefined,
    },
    resolver: yupResolver(schema),
  });

  const { data: advertisers, isLoading: advertisersLoading } =
    useAdvertisersList({
      onSuccessFunction: (data) => {
        if (data?.length > 0 && !loggedInAdvertiserId)
          setValue("advertiserId", data?.[0]?.id);
      },
    });

  const onSubmit = async (record: any) => {
    try {
      const formData = new FormData();
      formData.append("file", record.file);
      formData.append("advertiserId", record.advertiserId);

      const responce = await mutateAsync({
        payload: formData,
      });
      setInvalidDataCount(responce.invalidDataCount);
      setSnackbarState({
        open: true,
        severity: "success",
        message: messages.createdSuccessfully("File"),
      });
    } catch (error: any) {
      setSnackbarState({
        open: true,
        severity: "success",
        message: error?.errMsg ?? messages.somethingWentWrong,
      });
    }
  };
  return (
    <CustomContainer>
      <CardContainer sx={{ p: 3 }}>
        <Box sx={{ marginBottom: "30px" }}>
          <Typography variant="body1Semi">
            {" "}
            Upload linkedIn Engagement file here...
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            marginBottom: "30px",
          }}
        >
          <CustomAutocomplete
            name="advertiserId"
            control={control}
            placeholder="Select Advertiser"
            loading={advertisersLoading}
            type="secondary"
            disabled={Boolean(loggedInAdvertiserId)}
            itemList={advertisers?.map((item: any) => ({
              label: item.name,
              value: item.id,
            }))}
            disableClearable
          />
        </Box>
        <Box
          sx={{
            marginTop: "-7px",
          }}
        >
          <CustomFileInput
            name="file"
            control={control}
            error={errors?.file?.message}
            inputProps={{
              accept: ".csv",
            }}
          />
        </Box>
        {invalidDataCount > 0 && (
          <Typography>
            {" "}
            Invalid data records count is : {invalidDataCount}
          </Typography>
        )}

        <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
          <CustomLoadingButton
            title="Submit"
            isLoading={false}
            // fullWidth
            type="submit"
            variant="custom1"
            disabled={false}
            sx={{ width: "150px" }}
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          />
        </Box>
      </CardContainer>
    </CustomContainer>
  );
}
