import { item } from "@/components/Button/CustomToggleButton";
import { CHANNELS, CHANNELS_ICONS } from "@/constants";
import { ICompany } from "@/modules/Company/companies.interface";
import { Channel } from "@/types";
import { Key } from "react";
import { AUDIENCE_TYPES, FACEBOOK_BUDGET_TYPE } from "../../campaign.constant";

export const displayItem = (item?: any, campaign?: any) => ({
  channel: CHANNELS.DISPLAY,
  id: item?.id,
  name: item?.name,
  type: null,
  startDate: null,
  endDate: null,
  budget: null,
  budgetDaily: null,
  budgetType: String(campaign?.BeeswaxCampaign?.budgetType),
  bidding_strategy: "",
  bid: "",
  ecpc_target: "",
  pacing: "",
  audienceType: "",
  audienceIds: [],
  country: [],
  removeFraudulentUsers: false,
  creatives: [],
  active: item?.active ?? false,
  utmId: null,
  status: STATUS.INACTIVE,
  frequencyCap: [defaultFrequencyCap],
  freqCapUserType: null,
});
export const linkedInItem = (item?: any) => ({
  channel: CHANNELS.LINKEDIN,
  id: item?.id,
  name: item?.name,
  type: null,
  startDate: null,
  endDate: null,
  budget: null,
  budgetDaily: null,
  budgetType: null,
  audienceType: "",
  audienceIds: [],
  country: [],
  removeFraudulentUsers: false,
  creatives: [],
  optimizationGoal: null,
  biddingStrategy: null,
  bid: "",
  active: item?.active ?? false,
  utmId: null,
  status: STATUS.INACTIVE,
});

export const facebookItem = (item?: any, campaign?: any) => ({
  channel: CHANNELS.FACEBOOK,
  id: item?.id ?? null,
  name: item?.name ?? "",
  type: null,
  startDate: null,
  endDate: null,
  budget: null,
  budgetType: FACEBOOK_BUDGET_TYPE.daily_budget,
  bid: null,
  optimizationGoal: null,
  audienceType: null,
  audienceIds: [],
  creatives: [],
  utmId: null,
  status: STATUS.INACTIVE,
});

export const channelList = (
  advertiser: ICompany,
  isBeeswaxCampaign: boolean,
  isLinkedinCampaign: boolean,
  isFacebookCampaign: boolean
) => {
  const channels = [];
  if (advertiser?.dspAccountId && isBeeswaxCampaign)
    channels.push({ label: "Display", value: CHANNELS.DISPLAY });
  if (advertiser?.linkedinPageId && isLinkedinCampaign)
    channels.push({ label: "Linkedin", value: CHANNELS.LINKEDIN });
  if (advertiser?.facebookAdAccount?.fbAdAccountId && isFacebookCampaign)
    channels.push({ label: "Facebook", value: CHANNELS.FACEBOOK });
  return channels;
};

export const requiredFieldsForLauch = [
  "utmId",
  "creatives",
  "audienceType",
  "audienceIds",
];

export const STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};
export const STATUS_LINKEDIN = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
  COMPLETED: "COMPLETED",
  PAUSED: "PAUSED",
};
export const buttonList: item[] = [
  {
    label: "Active",
    value: STATUS.ACTIVE,
    color: "success",
  },
  { label: "InActive", value: STATUS.INACTIVE, color: "error" },
];

export const defaultFrequencyCap = {
  numberOfAds: null,
  duration: null,
  durationType: null,
};

const fieldNames = {
  name: "name",
  type: "type",
  startDate: "startDate",
  endDate: "endDate",
} as const;

type FieldName = keyof typeof fieldNames;
type FieldRequired = {
  [key in FieldName]?: {
    [key in Channel]: (args: any) => boolean;
  };
};
export const fieldRequired: FieldRequired = {
  [fieldNames.endDate]: {
    display: (args) => false,
    linkedin: (args) => {
      return args.watchPacingValue === "lifetime" ? true : false;
    },
    facebook: (args) => {
      return false;
    },
  },
};

export function facebookBudgetKeyToBudgetType(data: any) {
  if (data.daily_budget && parseInt(data.daily_budget) > 0) {
    return FACEBOOK_BUDGET_TYPE.daily_budget;
  }
  if (data.lifetime_budget && parseInt(data.lifetime_budget) > 0) {
    return FACEBOOK_BUDGET_TYPE.lifetime_budget;
  }
  return FACEBOOK_BUDGET_TYPE.daily_budget;
}

export function facebookAudienceType(data: any) {
  if (!data?.audiences?.length) return null;
  return data.audiences[0].audienceModel;
}
export function mapfacebookAudienceIds(data: any) {
  if (!data?.audiences?.length) return [];
  return data.audiences.map((audience: any) => audience.audienceId);
}
export function mapfacebookCreatives(data: any) {
  if (!data?.creatives?.length) return [];
  return data.creatives.map((creativeId: any) => creativeId);
}

export function convertUnitForFacebookPaylaod(value: string | number) {
  if (value) return parseFloat(value.toString()) * 100;
  return value;
}
export function convertUnitForFacebookMapping(value: string | number) {
  if (value) return parseFloat(value.toString()) / 100;
  return value;
}

export function facebookDirtyFields(dirtyFields: Record<string, boolean>) {
  const fields: Record<string, boolean> = { ...dirtyFields };
  if (fields.audienceIds) {
    fields["audienceType"] = true;
  }
  if (fields.budget) {
    fields["budgetType"] = true;
  }
  fields["channel"] = true;
  fields["status"] = true;

  return fields;
}
export function parseBudget(value: string | undefined | null) {
  if (value) return parseInt(value) / 100;
  return null;
}
export const LOGO = {
  [CHANNELS.DISPLAY]: CHANNELS_ICONS.DISPLAY,
  [CHANNELS.LINKEDIN]: CHANNELS_ICONS.LINKEDIN,
  [CHANNELS.FACEBOOK]: CHANNELS_ICONS.FACEBOOK,
};

export const DEFAULT_TITLE = {
  [CHANNELS.DISPLAY]: CHANNELS.DISPLAY,
  [CHANNELS.LINKEDIN]: CHANNELS.LINKEDIN,
  [CHANNELS.FACEBOOK]: "META",
};
