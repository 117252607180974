import { updatePassword } from "@/apis/v1/user";
import CustomLoadingButton from "@/components/Button/CustomLoadingButton";
import CustomInput from "@/components/Form/InputFields/CustomInput";
import SpacingGrid from "@/components/Grid/SpacingGrid";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { useUserContext } from "@/context/UserContext";
import { messages } from "@/messages";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AccountPage } from ".";

type AccountForm = yup.InferType<typeof validationSchema>;

export default function AccountForm({ setActivePage }: AccountFormProps) {
  const { user } = useUserContext();
  const { mutateAsync, isLoading } = useMutation(updatePassword);
  const { setSnackbarState } = useSnackbarDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AccountForm>({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(validationSchema),
  });

  async function onFormSubmit(data: AccountForm) {
    try {
      await mutateAsync({
        id: user.id,
        payload: {
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        },
      });

      setSnackbarState({
        open: true,
        severity: "success",
        message: messages.updatedSuccessfully("Password"),
      });
      setActivePage("Detail");
    } catch (error: any) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.errMsg ?? messages.somethingWentWrong,
      });
    }
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <SpacingGrid spacingType="form">
        <SpacingGrid item>
          <CustomInput
            name="oldPassword"
            label="Old Password"
            inputType="password"
            control={control}
            error={errors?.oldPassword?.message}
            type="secondary"
          />
        </SpacingGrid>
        <SpacingGrid item>
          <CustomInput
            name="newPassword"
            label="New Password"
            inputType="password"
            control={control}
            error={errors?.newPassword?.message}
            type="secondary"
          />
        </SpacingGrid>
        <SpacingGrid item>
          <CustomInput
            name="confirmPassword"
            label="Confirm Password"
            inputType="password"
            control={control}
            error={errors?.confirmPassword?.message}
            type="secondary"
          />
        </SpacingGrid>
        <SpacingGrid item>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 1 }}
          >
            <Box>
              <CustomLoadingButton
                isLoading={false}
                disabled={false}
                title="Back"
                type="button"
                variant="custom2"
                onClick={() => setActivePage("Detail")}
              />
            </Box>
            <Box>
              <CustomLoadingButton
                isLoading={isLoading}
                disabled={false}
                title="Submit"
                type="submit"
                variant="custom1"
              />
            </Box>
          </Box>
        </SpacingGrid>
      </SpacingGrid>
    </form>
  );
}

interface AccountFormProps {
  setActivePage: React.Dispatch<React.SetStateAction<AccountPage>>;
}

const validationSchema = yup.object({
  oldPassword: yup.string().required(messages.requiredField),
  newPassword: yup.string().required(messages.requiredField),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), ""], messages.passwordNotMatching)
    .required(messages.requiredField),
});
