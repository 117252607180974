import { editCampaign } from "@/apis/v1/campaign";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { messages } from "@/messages";
import routes from "@/routes";
import filterDirtyFields from "@/utils/filterDirtyFields";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { validationSchema } from "../../../campaign.validation";
import { useCreateOrEditForm } from "../../campaign.hook";
import InfoForm from "../InfoForm";
type ICreateCampaign = yup.InferType<ReturnType<typeof validationSchema>>;

export default function EditCampaign({ campaign }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { mutateAsync, isLoading: editingCampaign } = useMutation(editCampaign);
  const { setSnackbarState } = useSnackbarDispatch();

  const { rForm } = useCreateOrEditForm(campaign, true);
  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
    trigger,
    getValues,
    setValue,
  } = rForm;

  async function onFormSubmit(data: ICreateCampaign) {
    try {
      const filteredData = filterDirtyFields(data, dirtyFields);
      await mutateAsync({
        payload: {
          display: filteredData,
          advertiserCompanyId: data.advertiserCompanyId,
        },
        id: id,
      });
      navigate(
        `${routes.DASHBOARD}/${routes.CAMPAIGNS.INDEX}/${routes.CAMPAIGNS.QUICK_CAMPAIGN.INDEX}`
      );
      setSnackbarState({
        open: true,
        message: messages.updatedSuccessfully("Campaign"),
      });
    } catch (error: any) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.errMsg ?? messages.somethingWentWrong,
      });
    }
  }

  return (
    <InfoForm
      control={control}
      errors={errors}
      onFormSubmit={handleSubmit(onFormSubmit)}
      trigger={trigger}
      getValues={getValues}
      setValue={setValue}
      isLoading={editingCampaign}
      isEdit
    />
  );
}
