import routes from "@/routes";
import { lazy } from "react";
const HvkSetting = lazy(() => import("@/modules/VisitorInsight/HvkSetting"));
const ListHvkSetting = lazy(
  () => import("@/modules/VisitorInsight/HvkSetting/List")
);

const CompanyInsightRoutes = {
  element: <HvkSetting />,
  path: routes.VISITOR_INSIGHTS.KEYWORD_SETTING.INDEX,
  children: [
    {
      element: <ListHvkSetting />,
      path: "",
    },
  ],
};

export default CompanyInsightRoutes;
