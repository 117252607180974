import { messages } from "@/messages";

export default function correctTypedErrorMessage(error: unknown) {
  return error &&
    typeof error === "object" &&
    "errMsg" in error &&
    typeof error.errMsg === "string"
    ? error.errMsg
    : messages.somethingWentWrong;
}
