import CardContainer from "@/components/Card/CardContainer";
import { QUOTA_TYPES } from "@/constants";
import { useUserContext } from "@/context/UserContext";
import { Container } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { useAgencyOrAdvertiserById } from "../Company/company.hook";
import { IQuota } from "../Quotas/quota.interface";
import AccountDetail from "./Detail";
import AccountForm from "./Form";

const PAGE = ["Detail", "Form"] as const;
const PAGE_COMPONENT = {
  [PAGE[0]]: AccountDetail,
  [PAGE[1]]: AccountForm,
};
export type AccountPage = (typeof PAGE)[number];

export default function AccountInfo() {
  const { user } = useUserContext();
  const { data: company } = useAgencyOrAdvertiserById({ id: user.companyId });
  const [activePage, setActivePage] = useState<AccountPage>("Detail");

  const data = React.useMemo(() => {
    if (!user || !company || !company.Quotas) return [];
    const [advertisersLimit, impressionsQuota, linkedinProfilesResolvedQuota] =
      company.Quotas.reduce(
        (acc: IQuota[], q: IQuota) => {
          switch (q.type) {
            case QUOTA_TYPES.ADVERTISERS:
              acc[0] = q;
              break;
            case QUOTA_TYPES.CAMPAIGN_IMPRESSIONS:
              acc[1] = q;
              break;
            case QUOTA_TYPES.LINKEDIN_PROFILES_RESOLVED:
              acc[2] = q;
              break;
          }
          return acc;
        },
        [null, null, null]
      );
    return [
      {
        label: "Username",
        value: user.username,
      },
      {
        label: "Email",
        value: user.email,
      },
      {
        label: "Company",
        value: company.name,
      },
      {
        label: "Advertisers Limit",
        value: getQuotaValue(advertisersLimit),
      },
      {
        label: "Campaign Impressions Limit",
        value: getQuotaValue(impressionsQuota),
      },
      {
        label: "Linkedin Profiles To Resolve Limit",
        value: getQuotaValue(linkedinProfilesResolvedQuota),
      },
    ];
  }, [user, company]);

  const Component = PAGE_COMPONENT[activePage];

  return (
    <Container maxWidth="md">
      <CardContainer sx={{ px: 4, py: 3, gap: 6 }}>
        <Component data={data} setActivePage={setActivePage} />
      </CardContainer>
    </Container>
  );
}

function getQuotaValue(quota: IQuota) {
  if (quota?.limit) {
    if (quota.limit === "Infinity") return "Unlimited";
    else if (quota.interval === "fixed") return quota.limit;
    else return quota.limit + "/" + quota.interval;
  }
}
