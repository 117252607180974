import CustomLabel from "@/components/Form/CustomLabel";
import CustomLoader from "@/components/Loader/CustomLoader";
import {
  EXTENDED_CHANNELS_AGENCY_PERMISSIONS,
  EXTENDED_CHANNELS_CRM_ADVERTISER_PERMISSIONS,
  FORM_COLUMN_SPACING,
  FORM_ROW_SPACING,
  MODULES,
} from "@/constants";
import { useCampaignsList } from "@/modules/Campaign/GeneralCampaign/campaign.hook";
import { CAMPAIGN_TYPE } from "@/modules/Campaign/campaign.constant";
import { useCampaignSetup } from "@/modules/Report/ReportSetup/reportSetup.hook";
import { useTargetAccountFiles } from "@/modules/TargetAccount/targetAccounts.hook";
import { Channel } from "@/types";
import { Box, Grid, Grow } from "@mui/material";
import * as React from "react";
import { useWatch } from "react-hook-form";
import { UserChildFormProps } from ".";
import { CREATE_USER_TYPE } from "../ListUser";
import {
  filterGlobalKey,
  filterSpecifiIds,
  integrationChannelsForAdvertiserUser,
  integrationChannelsForAgencyUser,
  modules,
} from "../data";
import { ChildModule, ExtendedChannelsCRMAdvertiser } from "../user.interface";
import CheckBoxModule from "./CheckBoxModule";
import ModuleAccessPermission from "./ModuleAccessPermission";
import ModuleFilesAccessPermission from "./ModuleFilesAccessPermission";

type QueryAdvertiserIds = {
  advertiserIds?: any[];
  userCompanyId?: number;
};
export default function ModuleForm({
  control,
  errors,
  activeStep,
  createUserType,
  permissions,
  isAdmin,
  isAdvertiser,
  isAgency,
  creatorOrEditorPermissions,
  isEdit = false,
  isAgencyAdminCreationOrEdit,
  beeswaxAccountId,
}: UserChildFormProps) {
  const [advertisersSpecificIds, companyId, advertiserId] = useWatch({
    name: ["advertisersSpecificIds", "companyId", "advertiserId"],
    control,
  });

  const queryAdvertiserIds: QueryAdvertiserIds = {};
  if (isAdmin) {
    if (createUserType === CREATE_USER_TYPE.INTERNAL_USER) {
      if (advertisersSpecificIds?.length) {
        queryAdvertiserIds["advertiserIds"] = advertisersSpecificIds;
      }
      queryAdvertiserIds["userCompanyId"] = companyId;
    } else {
      queryAdvertiserIds["userCompanyId"] = advertiserId;
      queryAdvertiserIds["advertiserIds"] = [advertiserId];
    }
  }
  if (isAgency && !isAdvertiser) {
    if (createUserType === CREATE_USER_TYPE.INTERNAL_USER) {
      if (advertisersSpecificIds?.length) {
        queryAdvertiserIds["advertiserIds"] = advertisersSpecificIds;
      }
    }
    if (createUserType === CREATE_USER_TYPE.ADVERTISER_USER) {
      queryAdvertiserIds["advertiserIds"] = [companyId];
    }
  }

  const { data: reports, isLoading: reportsLoading } = useCampaignSetup({
    queryParams: { isPagination: false, ...queryAdvertiserIds },
  });

  const { data: campaigns } = useCampaignsList({
    queryParams: {
      module: MODULES.CAMPAIGNS,
      isPagination: false,
      type: CAMPAIGN_TYPE.GENERAL,
      ...queryAdvertiserIds,
    },
  });
  const { data: campaignStats } = useCampaignsList({
    queryParams: {
      module: MODULES.CAMPAIGN_STATS,
      isPagination: false,
      ...queryAdvertiserIds,
      type: null,
    },
  });
  const { data: targetAccountFiles } = useTargetAccountFiles({
    queryParams: {
      fileType: "domains",
      status: "Completed",
      isPagination: false,
      ...queryAdvertiserIds,
    },
  });

  const watched = useWatch({
    name: ["reportSetup", "campaigns"],
    control,
  });

  const dependentValues = React.useMemo(() => {
    return {
      reportSetup: watched[0],
      campaigns: watched[1],
    };
  }, [watched[0], watched[1]]);

  const integrationChannelsByUserPermissions = () => {
    return (
      createUserType === CREATE_USER_TYPE.INTERNAL_USER
        ? integrationChannelsForAgencyUser
        : integrationChannelsForAdvertiserUser
    ).filter((channel) => {
      return permissions[
        (createUserType === CREATE_USER_TYPE.INTERNAL_USER
          ? EXTENDED_CHANNELS_AGENCY_PERMISSIONS[channel.value as Channel]
          : EXTENDED_CHANNELS_CRM_ADVERTISER_PERMISSIONS[
              channel.value as ExtendedChannelsCRMAdvertiser
            ]) as keyof typeof permissions
      ];
    });
  };
  const reqData = {
    report: filterSpecifiIds(
      campaignSetupList(reports),
      filterGlobalKey(creatorOrEditorPermissions[MODULES.REPORTS_SETUP]),
      isEdit
    ),
    campaigns: filterSpecifiIds(
      campaignMapping(campaigns?.rows),
      filterGlobalKey(creatorOrEditorPermissions[MODULES.CAMPAIGNS]),
      isEdit
    ),
    campaignStats: filterSpecifiIds(
      campaignMapping(campaignStats?.rows),
      filterGlobalKey(creatorOrEditorPermissions[MODULES.CAMPAIGN_STATS]),
      isEdit
    ),
    targetAccountFiles: filterSpecifiIds(
      targetAccountFilesList(targetAccountFiles),
      filterGlobalKey(creatorOrEditorPermissions[MODULES.AUDIENCE_MANAGER]),
      isEdit
    ),
    integrationChannels: integrationChannelsByUserPermissions(),
    beeswaxAccountId,
  };
  const commonProps = {
    control,
    errors,
    createUserType,
    permissions,
    dependentValues,
    isAgencyAdminCreationOrEdit,
  };

  const moduleKeys = Object.keys(modules({ data: reqData }));
  if (!permissions) return <CustomLoader />;
  return (
    <Grow in={activeStep === 1}>
      <Grid
        item
        container
        justifyContent="center"
        columnSpacing={FORM_COLUMN_SPACING}
        rowSpacing={FORM_ROW_SPACING}
      >
        {moduleKeys.map((key) => {
          const module = modules({ data: reqData, createUserType });
          const rootPermission = module[key].rootPermission;
          const isRootUserAllowed =
            (createUserType &&
              module[key]?.rootAllowedUserType?.includes(createUserType)) ??
            true;

          if (
            isRootUserAllowed &&
            rootPermission.some((permission) => permissions[permission])
          ) {
            return (
              <Module
                key={key}
                commonProps={commonProps}
                heading={module[key].heading}
                data={module[key].data}
                isEdit={isEdit}
              />
            );
          } else return null;
        })}
      </Grid>
    </Grow>
  );
}

function Module({ commonProps, heading, data, isEdit }: any) {
  return (
    <StyledGrid label={heading}>
      {data.map((x: ChildModule, i: number) => {
        const Component =
          componentsObject[x.componentName as keyof typeof componentsObject];
        return (
          <Component
            key={i}
            {...commonProps}
            componentData={x.componentData}
            isEdit={isEdit}
          />
        );
      })}
    </StyledGrid>
  );
}

function StyledGrid({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <Grid
      item
      container
      justifyContent="center"
      columnSpacing={FORM_COLUMN_SPACING}
      rowSpacing={FORM_ROW_SPACING}
    >
      <Grid item xs={12}>
        <CustomLabel>{label}</CustomLabel>
      </Grid>
      <Grid item xs={12}>
        <StyledSection>{children}</StyledSection>
      </Grid>
    </Grid>
  );
}

function StyledSection({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        sx={{
          width: "5px",
          backgroundColor: "#eee",
          borderRadius: "10px",
          ml: "20px",
          mr: "10px",
        }}
      />
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        {children}
      </Box>
    </Box>
  );
}

const componentsObject = {
  CheckBoxModule: CheckBoxModule,
  ModuleAccessPermission: ModuleAccessPermission,
  ModuleFilesAccessPermission: ModuleFilesAccessPermission,
};

function campaignSetupList(data: any) {
  return data?.rows?.map((item: any) => ({
    label: item.CampaignKey.keyName,
    value: item.id,
  }));
}

function targetAccountFilesList(data: any) {
  return data?.rows?.map((item: any) => ({
    label: item.fileName,
    value: item.id,
  }));
}

export function campaignMapping(campaigns: any) {
  return campaigns?.map((campaign: any) => ({
    value: campaign.id,
    label: campaign.name,
  }));
}
