import {
  getCampaignKeys,
  getCampaigns,
  getQuotaStats,
} from "@/apis/v1/campaign";
import { useQuery } from "@tanstack/react-query";
import { filterDataAccordingToAllowedAdvertisers } from "../../Advertiser/advertiser.validation";
import { CHANNELS, MODULES } from "@/constants";
import { validationSchema } from "../campaign.validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useUserContext } from "@/context/UserContext";
import dayjs from "dayjs";
import mapFilterQueryKeys from "@/utils/mapFilterQueryKeys";
import { omit } from "lodash";
import { useBeeswaxLineItem } from "../../Beeswax/beeswax.hook";
import { useLinkedinLineItem } from "../../Linkedin/linkedin.hook";
import { Channel } from "@/types";
import { useFacebookLineItem } from "../../LineItem/lineItem.hook";
import { defaultFrequencyCap } from "./CreateOrEditCampaign/data";

export function useCampaignKeysList(
  otherParams?: any,
  queryParams?: Record<string, any>
) {
  return useQuery(["quick-campaigns", "keys"], getCampaignKeys, {
    ...otherParams,
    meta: {
      queryParams: {
        type: MODULES.QUICK_CAMPAIGNS,
        ...queryParams,
      },
    },
  });
}

export const CAMPAIGNS_LIST_KEY = (keys?: Array<string | number | null>) => [
  "quick-campaigns",
  ...mapFilterQueryKeys(keys),
];

interface useCampaignsListArgs {
  queryParams: {
    module: string;
    advertiserId?: number | null;
    page?: number;
    size?: number;
    isPagination?: boolean;
    type?: string;
  };
}
export function useCampaignsList(meta?: useCampaignsListArgs) {
  return useQuery(
    CAMPAIGNS_LIST_KEY(
      Object.values(omit(meta?.queryParams, "isPagination") || [])
    ),
    getCampaigns,
    {
      meta: {
        queryParams: meta?.queryParams,
      },
      select: filterDataAccordingToAllowedAdvertisers("advertiserId"),
    }
  );
}
export function getQuotaStat({
  enabled,
  queryParams,
}: {
  enabled: boolean;
  queryParams: Record<string, any>;
}) {
  return useQuery(["quota-stats", queryParams.advertiserId], getQuotaStats, {
    enabled,
    meta: {
      queryParams,
    },
  });
}
export function useCreateOrEditForm(data?: any, isEdit = false) {
  const defaultValues = useDefaultValues(data);

  const campaignValidationSchema = validationSchema(isEdit);

  const rForm = useForm<any>({
    defaultValues,
    resolver: yupResolver(campaignValidationSchema),
  });

  return { rForm };
}

export const radioOptions = [
  { label: "Run continuously from start date", value: "single" },
  { label: "Set start and end date", value: "double" },
];

function useDefaultValues(data?: any) {
  const { loggedInAdvertiserId } = useUserContext();
  if (data) {
    let editDefaultValues: any = {
      name: data?.name ?? "",
      advertiserCompanyId: data?.advertiserId ?? "",
      startDate: data?.startDate,
      totalImpression: data?.totalImpression,
      endDate: data?.endDate,
      dateType: data?.dateType === "double" ? "DOUBLE" : data?.dateType,
      audienceIds: data?.audienceIds,
      creatives: data?.creatives,
      executionType: data?.campaignType,
      lineItemType: data?.lineItemType.toString(),
      numberOfAds: data?.numberOfAds,
      durationType: data?.durationType,
      status: data?.status,
    };
    if (data["BeeswaxCampaign"]) {
      editDefaultValues = {
        ...editDefaultValues,
        [CHANNELS.DISPLAY]: {
          continents: data?.BeeswaxCampaign?.continent ?? "",
          budgetType: data?.BeeswaxCampaign?.budgetType ?? "",
          budgetValue: data?.BeeswaxCampaign?.budget ?? null,
        },
      };
    }

    return editDefaultValues;
  }

  return {
    name: "",
    advertiserCompanyId: loggedInAdvertiserId ?? "",
    startDate: dayjs(),
    frequencyCap: [defaultFrequencyCap],
    dateType: radioOptions[0].value,
    status: false,
    lineItemType: "0", // set default line item type
    durationType: 86400,
  };
}

export function useLineItem({
  args,
  channel,
}: {
  args: { enabled: boolean; onSuccess: (data: any) => void; id: any };
  channel: Channel;
}) {
  return hook[channel](args);
}
const hook = {
  [CHANNELS.DISPLAY]: useBeeswaxLineItem,
  [CHANNELS.LINKEDIN]: useLinkedinLineItem,
  [CHANNELS.FACEBOOK]: useFacebookLineItem,
};
