import { getFacebookLineItem, getLineItems } from "@/apis/v1/lineItem";
import { useQuery } from "@tanstack/react-query";

export function useLineItemsList(meta: { queryParams: Record<string, any> }) {
  const key = ["lineItems"];
  if (meta.queryParams) key.push(...Object.values(meta.queryParams));
  return useQuery(key, getLineItems, {
    meta,
  });
}

export const FACEBOOK_LINEITEM_KEYS = {
  default: () => ["lineitem", "facebook"],
  all: () => [...FACEBOOK_LINEITEM_KEYS.default(), "all"],
  withId: ({ id }: any) => [...FACEBOOK_LINEITEM_KEYS.default(), id],
};
export function useFacebookLineItem<TDATA>({
  id,
  ...rest
}: {
  id: any;
  enabled?: boolean;
  onSuccess?: (data: any) => void;
  select?: (data: any) => any;
}) {
  return useQuery<TDATA>(
    FACEBOOK_LINEITEM_KEYS.withId({ id }),
    getFacebookLineItem,
    {
      meta: {
        id,
      },
      ...rest,
    }
  );
}
