import { MODULES } from "@/constants";
import { IPermissions } from "@/modules/User/user.interface";
import routes from "@/routes";
import { lazy } from "react";
import CompanyInsightRoutes from "./companyInsight.routes";
import HvkSettingRoute from "./hvkSettings.routes";
import ProfileInsightRoutes from "./profileInsight.routes";
const VisitorInsight = lazy(() => import("@/modules/VisitorInsight"));

const VisitorInsightRoutes = (permission: IPermissions) => {
  const children = [];
  if (permission[MODULES.COMPANY_VISITOR_INSIGHTS]) {
    children.push(CompanyInsightRoutes);
  }
  if (permission[MODULES.PROFILE_VISITOR_INSIGHTS]) {
    children.push(ProfileInsightRoutes);
  }
  if (
    permission[MODULES.COMPANY_VISITOR_INSIGHTS] ||
    permission[MODULES.PROFILE_VISITOR_INSIGHTS]
  ) {
    children.push(HvkSettingRoute);
  }
  return {
    element: <VisitorInsight />,
    path: routes.VISITOR_INSIGHTS.INDEX,
    children,
  };
};

export default VisitorInsightRoutes;
