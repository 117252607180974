import { ImageLoadingState } from "@/types";
import { Box } from "@mui/system";
import * as React from "react";
import DummyIcon from "../Icon/Dummy";
import ImagePlaceHolder from "../Placeholder/ImagePlaceHolder";

export default function Image({
  img,
  alt,
  maxWidth = "100%",
  maxHeight = "inherit",
  loadedStatus,
  objectFit = "contain",
  containerWidth = "100%",
  contanierHeight = "100%",
  imageWidth = "100%",
  imageHeight = "100%",
  borderRadius = 0,
}: ImageProps): ReturnType<React.FC> {
  const SourceImage = (
    <img
      src={img}
      alt={alt ?? "img"}
      style={{
        width: imageWidth,
        height: imageHeight,
        objectFit: objectFit,
        maxWidth,
        maxHeight,
      }}
    />
  );
  const ImageComponent = loadedStatus ? (
    loadedStatus === "loading" ? (
      <ImagePlaceHolder width={maxWidth} height={maxHeight} />
    ) : loadedStatus === "error" ? (
      <DummyIcon />
    ) : (
      SourceImage
    )
  ) : (
    SourceImage
  );
  return (
    <Box
      sx={{
        width: containerWidth,
        height: contanierHeight,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        borderRadius,
        overflow: "hidden",
      }}
    >
      {ImageComponent}
    </Box>
  );
}

interface ImageProps {
  img: string;
  alt: string;
  maxWidth?: string;
  maxHeight?: string;
  containerWidth?: string;
  contanierHeight?: string;
  imageWidth?: string;
  imageHeight?: string;
  loadedStatus?: ImageLoadingState;
  objectFit?:
    | "cover"
    | "fill"
    | "none"
    | "contain"
    | "scale-down"
    | "inherit"
    | "initial";
  borderRadius?: string | 0;
}
