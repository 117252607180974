import { Alert, AlertColor } from "@mui/material";
import { CustomContentProps, SnackbarContent, closeSnackbar } from "notistack";
import * as React from "react";
interface CustomSnackbarSecondaryProps extends CustomContentProps {
  severity: AlertColor;
}
const CustomSnackbarSecondary = React.forwardRef<
  HTMLDivElement,
  CustomSnackbarSecondaryProps
>(function CustomSnackbarSecondary(props, ref) {
  const {
    // You have access to notistack props and options 👇🏼
    id,
    message,
    // as well as your own custom props 👇🏼
    severity,
    iconVariant,
    ...other
  } = props;

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      <Alert
        id={id as string}
        severity={severity}
        sx={{ minWidth: "300px" }}
        onClose={() => {
          closeSnackbar(id);
        }}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});

export default CustomSnackbarSecondary;
