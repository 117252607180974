import CreateLinkedInEngagement from "@/modules/LinkedInEngagement/create/index";
import routes from "@/routes";
import { lazy } from "react";
const LinkedInEngagement = lazy(() => import("@/modules/LinkedInEngagement"));

const LinkedInEngagementRoutes = {
  element: <LinkedInEngagement />,
  path: routes.LINKEDIN_ENGAGEMANT.INDEX,

  children: [
    {
      element: <CreateLinkedInEngagement />,
      path: "",
    },
  ],
};

export default LinkedInEngagementRoutes;
