import {
  deleteUser,
  getCompleteProfileURL,
  getUserById,
  getUsers,
  loginAs,
} from "@/apis/v1/user";
import {
  QueryMeta,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { IUserLoginResponse } from "./user.interface";
import { SPECIFIC_COMPANY_ID_TABS } from "@/components/Drawer/data";
import { useUserContext } from "@/context/UserContext";
import { filterDataAccordingToAllowedAdvertisers } from "../Advertiser/advertiser.validation";
import mapFilterQueryKeys from "@/utils/mapFilterQueryKeys";

export const USERS_KEY = "users";

export const USERS_LIST_KEY = (keys?: Array<string | number | undefined>) => [
  USERS_KEY,
  ...mapFilterQueryKeys(keys),
];

interface useUsersListArgs extends QueryMeta {
  queryParams?: {
    advertiserId?: number;
    page?: number;
    size?: number;
  };
}

export function useUsersList(meta?: useUsersListArgs, enabled = true) {
  return useQuery(
    USERS_LIST_KEY(Object.values(meta?.queryParams || [])),
    getUsers,
    {
      meta: {
        ...meta,
        isPagination: true,
      },
      select: filterDataAccordingToAllowedAdvertisers("companyId", true),
      enabled,
    }
  );
}

export function useUser(
  meta: { userId: number | string },
  options?: Omit<
    UseQueryOptions<any, unknown, any, (string | number)[]>,
    "initialData" | "queryKey" | "queryFn"
  >
) {
  return useQuery([USERS_KEY, meta.userId], getUserById, {
    meta,
    ...options,
  });
}

export function useLoginAsUser(meta: { userId: string; token?: string }) {
  return useQuery<IUserLoginResponse>([USERS_KEY, meta.userId], loginAs, {
    meta,
    enabled: false,
  });
}

export function useCompleteProfileUrl(meta?: { userId: string | number }) {
  return useQuery(
    [USERS_KEY, meta?.userId, "completeProfileLink"],
    getCompleteProfileURL,
    {
      meta,
      enabled: false,
    }
  );
}

export function useDeleteUser() {
  return useMutation(deleteUser);
}

export function useCheckSpecificUser() {
  const { user } = useUserContext();
  return Boolean(
    Object.keys(SPECIFIC_COMPANY_ID_TABS).includes(
      String(user.parentCompanyId ?? user.companyId)
    )
  );
}
