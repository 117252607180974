import ContanuityLogo from "@/assets/logo/contanuity-logo.png";
import ContanuityMiniLogo from "@/assets/logo/contanuity-miniLogo.png";
import DemandScienceLogo from "@/assets/logo/demandscience-logo.png";
import DemandScienceMiniLogo from "@/assets/logo/demandscience-miniLogo.png";
import IntentsifyLogo from "@/assets/logo/Intentsify-logo.png";
import IntentsifyMiniLogo from "@/assets/logo/Intentsify-miniLogo.png";

const config = {
  CONTANUITY: {
    theme: "tertiary",
    brand: {
      logo: ContanuityLogo,
      miniLogo: ContanuityMiniLogo,
      name: "Contanuity",
      title: "Contanuity",
      favFolderName: "contanuity",
    },
    pixel: {
      url: "https://tracking.contanuity.com",
      identifierName: "CONTANUITY_TAG",
    },
  },
  DEMANDSCIENCE: {
    theme: "default",
    brand: {
      logo: DemandScienceLogo,
      miniLogo: DemandScienceMiniLogo,
      name: "Demand Science",
      title: "ABM Display by DemandScience",
      favFolderName: "demand-science",
    },
    pixel: {
      url: "https://abm-tracking.demandscience.com",
      identifierName: "TAG_INFO",
    },
  },
  INTENTSIFY: {
    theme: "tertiary",
    brand: {
      logo: IntentsifyLogo,
      miniLogo: IntentsifyMiniLogo,
      name: "Intentsify",
      title: "Intentsify",
      favFolderName: "intentsify",
    },
    pixel: {
      url: "https://tracking.contanuity.com",
      identifierName: "CONTANUITY_TAG",
    },
  },
};

export default config;
