export const ENGEGEMENT_TIME_SPAN = [
  {
    label: "30 days in the past",
    value: 30,
  },
  {
    label: "60 days in the past",
    value: 60,
  },
  {
    label: "90 days in the past",
    value: 90,
  },
  {
    label: "180 days in the past",
    value: 180,
  },
  {
    label: "365 days in the past",
    value: 365,
  },
];
export const RETARGET_BY = {
  COMPANY_PAGE: "companyPage",
  VIDEO_ADS: "videoAds",
  SINGLE_IMAGE_ADS: "singleImageAds",
  WEBSITE_RETARGETING: "websiteRetargeting",
};
export const RETARGET_BY_ITEMS = [
  { label: "Website retargeting	", value: RETARGET_BY.WEBSITE_RETARGETING },
  { label: "Company Page", value: RETARGET_BY.COMPANY_PAGE },
  { label: "Video Ads", value: RETARGET_BY.VIDEO_ADS },
  { label: "Single image ads", value: RETARGET_BY.SINGLE_IMAGE_ADS },
];
export const linkedinMatchTypeList = [
  { label: "Exact", value: "EXACT" },
  { label: "Prefix", value: "STARTS_WITH" },
  { label: "Keyword", value: "CONTAINS" },
];
