import routes from "@/routes";
import { lazy } from "react";
const ProfileInsight = lazy(
  () => import("@/modules/VisitorInsight/ProfileInsight")
);
const ListProfileInsight = lazy(
  () => import("@/modules/VisitorInsight/ProfileInsight/ListInsight")
);

const ProfileInsightRoutes = {
  element: <ProfileInsight />,
  path: routes.VISITOR_INSIGHTS.PROFILE.INDEX,
  children: [
    {
      element: <ListProfileInsight />,
      path: "",
    },
  ],
};

export default ProfileInsightRoutes;
