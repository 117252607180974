import StyledSwitch from "@/components/Switch/StyledSwitch.style";
import { formFieldError } from "@/types";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useController, UseControllerProps } from "react-hook-form";

export default function CustomSwitchSecondary({
  name,
  control,
  isLabel = false,
  error,
  switchSize = "medium",
  disabled = false,
}: CustomAutocompleteProps) {
  const { field } = useController({ control, name });
  return (
    <Box>
      <FormControlLabel
        control={
          <StyledSwitch checked={field?.value} {...field} size={switchSize} />
        }
        label={isLabel ? (field.value ? "active" : "inactive") : ""}
        disabled={disabled}
      />
      <Box sx={{ mx: "14px" }}>
        {error && <FormHelperText error>{`${error}`}</FormHelperText>}
      </Box>
    </Box>
  );
}

interface CustomAutocompleteProps extends UseControllerProps {
  isLabel?: boolean;
  error?: formFieldError;
  switchSize?: "medium" | "small";
  disabled?: boolean;
}
