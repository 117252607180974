import { grey } from "@mui/material/colors";
import { PaletteOptions } from "@mui/material/styles";
const paletteValues: Record<any, PaletteOptions> = {
  default: {
    mode: "light",
    primary: {
      light: "#ED8187",
      main: "#EB444E",
      dark: "#d43d46",
    },
    secondary: {
      light: "#384c67",
      main: "#223856",
      dark: "#1f324d",
      contrastText: "#fff",
    },
    tertiary: {
      main: "#A90016",
      light: "#FEDADF",
      dark: "#870012",
    },
    quaternary: {
      light: "#c3dcf7",
      main: "#81b2ea",
      dark: "#6392e0",
    },
    accent: {
      dark: "#4D4D4D",
      main: "#999999",
      light: "#F2F2F2",
    },
    inactive: {
      light: grey[200],
      main: grey[500],
      dark: grey[700],
      contrastText: grey[600],
    },
    background: {
      default: "#F8FAFB",
      paper: "#fff",
    },
    negative: {
      main: "#A90016",
      light: "#FEDADF",
      dark: "#870012",
    },
    positive: {
      main: "#3CBB1D",
      light: "#E2FBDA",
      dark: "#2a8314",
    },
  },
  secondary: {
    mode: "light",
    primary: {
      light: "#8C8C8C",
      main: "#333333",
      dark: "#000000",
    },
    secondary: {
      main: "#4D4D4D",
    },
    tertiary: {
      light: "#8C8C8C",
      main: "#333333",
      dark: "#000000",
    },
    quaternary: {
      light: "#8C8C8C",
      main: "#333333",
      dark: "#000000",
    },
    accent: {
      dark: "#4D4D4D",
      main: "#8C8C8C",
      light: "#F2F2F2",
    },
    negative: {
      light: "#8C8C8C",
      main: "#333333",
      dark: "#000000",
    },
    positive: {
      light: "#8C8C8C",
      main: "#333333",
      dark: "#000000",
    },
    background: {
      default: "#fff",
      paper: "#F2F2F2",
    },
    inactive: {
      light: grey[200],
      main: grey[500],
      dark: grey[700],
      contrastText: grey[600],
    },
  },
  tertiary: {
    mode: "light",
    primary: {
      light: "#1e7d71",
      main: "#050f27",
      dark: "#0b574d",
    },
    secondary: {
      light: "#1e7d71",
      main: "#050f27",
      dark: "#0b574d",
    },
    tertiary: {
      main: "#A90016",
      light: "#FEDADF",
      dark: "#870012",
    },
    quaternary: {
      light: "#c3dcf7",
      main: "#81b2ea",
      dark: "#6392e0",
    },
    accent: {
      dark: "#4D4D4D",
      main: "#999999",
      light: "#F2F2F2",
    },
    inactive: {
      light: grey[200],
      main: grey[500],
      dark: grey[700],
      contrastText: grey[600],
    },
    background: {
      default: "#ffffff",
      paper: "#f7f9fa",
    },
    negative: {
      main: "#A90016",
      light: "#FEDADF",
      dark: "#870012",
    },
    positive: {
      main: "#3CBB1D",
      light: "#E2FBDA",
      dark: "#2a8314",
    },
  },
};

export default paletteValues;
