import CustomSwitch from "@/components/Switch/CustomSwitch";
import {
  Box,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from "@mui/material";
import * as React from "react";
import CardContainer from "../CardContainer";

// mui icon
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { CardContainerProps } from "../card.interface";

type ExpandToggleOn = "icon" | "header" | "switch";
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  expandToggleOn: ExpandToggleOn;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, expandToggleOn, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand, expandToggleOn }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  cursor: expandToggleOn === "icon" ? "pointer" : "default",
}));

export default function CardExpandable({
  title,
  Icon,
  ActionIconPrimary = null,
  ActionIconSecondary = null,
  children,
  check = false,
  disabled = false,
  setCheck,
  isChecked = false,
  expandToggleOn = "icon",
  switchLabel = true,
  customHandleCheck = undefined,
  showIcon = true,
  cardContainerProps,
}: CardExpandableProps) {
  const [expanded, setExpanded] = React.useState(check);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const StyledBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    gap: 4,
  }));

  const expandFunction = (value: ExpandToggleOn) => {
    return expandToggleOn === value ? handleExpandClick : undefined;
  };

  return (
    <CardContainer sx={{ padding: "16px 18px" }} {...cardContainerProps}>
      <StyledBox
        sx={{
          justifyContent: "space-between",
          cursor: expandFunction("header") ? "pointer" : "default",
        }}
        onClick={expandFunction("header")}
      >
        <StyledBox>
          <Typography variant="bodyBigBold" sx={{ pl: 1 }}>
            {title}
          </Typography>
          {Icon && <Icon width="25" height="25" />}
        </StyledBox>
        <StyledBox>
          {isChecked && (
            <CustomSwitch
              disabled={disabled}
              check={check}
              setCheck={setCheck}
              isLabel={switchLabel}
              customHandleCheck={customHandleCheck}
            />
          )}
          {ActionIconPrimary && (
            <IconButton>
              <ActionIconPrimary />
            </IconButton>
          )}
          {ActionIconSecondary && (
            <IconButton>
              <ActionIconSecondary />
            </IconButton>
          )}
          {showIcon && (
            <ExpandMore
              expand={expandToggleOn === "switch" ? check : expanded}
              aria-expanded={expandToggleOn === "switch" ? check : expanded}
              aria-label="show more"
              onClick={
                expandToggleOn === "icon" ? expandFunction("icon") : () => null
              }
              disabled={expandToggleOn === "icon" ? false : true}
              expandToggleOn={expandToggleOn}
            >
              {children == null ? (
                <RemoveCircleOutlineOutlinedIcon />
              ) : (
                <ExpandCircleDownOutlinedIcon />
              )}
            </ExpandMore>
          )}
        </StyledBox>
      </StyledBox>
      <Collapse
        in={expandToggleOn === "switch" ? check : expanded}
        timeout="auto"
        unmountOnExit
      >
        {children}
      </Collapse>
    </CardContainer>
  );
}

interface CardExpandableProps {
  Icon?: React.ElementType;
  ActionIconPrimary?: React.ElementType | null;
  ActionIconSecondary?: React.ElementType | null;
  children: React.ReactNode;
  title: string;
  check?: boolean;
  disabled?: boolean;
  setCheck?: React.Dispatch<React.SetStateAction<boolean>>;
  isChecked?: boolean;
  expandToggleOn?: ExpandToggleOn;
  switchLabel?: boolean;
  customHandleCheck?: any;
  showIcon?: boolean;
  cardContainerProps?: CardContainerProps;
}
