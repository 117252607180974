import { unzip } from "unzipit";
import { messages } from "@/messages";
import { ALLOWED_VIDEO_TYPES, ALLOWED_IMAGES_TYPES } from "@/constants";
import { getVideoDuration } from "@/utils/helpers";
import getDimentionsFromImageDataUrl from "@/utils/getDimentionsFromImageDataUrl";

export const createBulkLinkedinAssets = async (
  file: any,
  advertiserId: any,
  zipFile_CB: any
) => {
  const assetsPromiseList = [];
  const assetNames = [];
  const { entries } = await unzip(file);
  for (const [name, entry] of Object.entries(entries)) {
    if (isValidEntry(name)) {
      const formData = new FormData();
      const mimeType = getFiletype(name);
      formData.append("image", await entry.blob(mimeType));
      formData.append("advertiserId", advertiserId);
      formData.append("isVideo", JSON.stringify(isZipEntryVideo(name)));
      formData.append("fileSize", JSON.stringify(entry.size));
      assetNames.push(getFileName(name));
      assetsPromiseList.push(zipFile_CB({ data: formData, type: "linkedin" }));
    }
  }
  return {
    assetsPromiseList: await Promise.all(assetsPromiseList),
    assetNames,
  };
};

const isValidEntry = (name: string) =>
  isNonHiddenJunkEntry(name) &&
  (isZipEntryVideo(name) || isZipEntryImage(name));
const isNonHiddenJunkEntry = (name: string) => {
  return !name.startsWith("__MACOSX") && name.split(".").length == 2;
};
const getFiletype = (name: string) => {
  return isZipEntryVideo(name)
    ? `video/${getTypeExtention(name)}`
    : `image/${getTypeExtention(name)}`;
};
const isZipEntryVideo = (name: string) => {
  const splitedArray = name.split(".");
  return ALLOWED_VIDEO_TYPES.includes(splitedArray[splitedArray.length - 1]);
};
const isZipEntryImage = (name: string) => {
  const splitedArray = name.split(".");
  return ALLOWED_IMAGES_TYPES.includes(splitedArray[splitedArray.length - 1]);
};

const getTypeExtention = (name: string) => {
  const splitedArray = name.split(".");
  return splitedArray[splitedArray.length - 1];
};

export const validateZipEntriesForDisplay = async (file: any) => {
  const { entries } = await unzip(file);
  for (const [name, entry] of Object.entries(entries)) {
    if (isValidEntry(name)) {
      if (!isZipEntryImage(name))
        return {
          isError: true,
          message: messages.invalidZipEntryType,
        };
    }
  }
  return {
    isError: false,
  };
};

export const validateZipEntriesForLinkedin = async (file: any) => {
  const { entries } = await unzip(file);
  for (const [name, entry] of Object.entries(entries)) {
    if (isValidEntry(name)) {
      if (!isZipEntryImage(name) && !isZipEntryVideo(name))
        return {
          isError: true,
          message: messages.invalidZipEntryType,
        };
    }
  }
  return {
    isError: false,
  };
};

export const getFileName = (name: string) => {
  const splitedArray = name.split("/");
  return splitedArray[splitedArray.length - 1];
};

export const getAssetInfo = async (
  key: string,
  file: any,
  fileName: string
): Promise<any> => {
  const assetInfo = {};
  const { entries } = await unzip(file);
  for (const [name, entry] of Object.entries(entries)) {
    if (isValidEntry(name)) {
      const mimeType = getFiletype(name);
      const fileBlob = await entry.blob(mimeType);

      const dimentions = isZipEntryImage(name)
        ? await getDimentionsFromImageDataUrl(
            window.URL.createObjectURL(fileBlob)
          )
        : { width: 0, height: 0 };
      return {
        dimentions,
        fileSize: entry.size,
        fileName: getFileName(name),
        duration: isZipEntryVideo(name)
          ? await getVideoDuration(fileBlob)
          : undefined,
      };
    }
  }
  return assetInfo;
};
