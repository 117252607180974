import { ImageLoadingState } from "@/types";
import { useState, useEffect } from "react";

const useImageLoaded = ({
  src,
  srcSet,
  srcLoading = false,
}: {
  src: string;
  srcSet?: string;
  srcLoading?: boolean;
}) => {
  const [loadedState, setLoadedState] = useState<ImageLoadingState>("loading");

  useEffect(() => {
    if (!srcLoading && !src && !srcSet) {
      setLoadedState("error");
      return;
    }

    let active = true;
    const image = new Image();
    image.src = src;
    if (srcSet) {
      image.srcset = srcSet;
    }
    image.onload = () => {
      if (!active) {
        return;
      }
      setLoadedState("loaded");
    };
    image.onerror = () => {
      if (!active) {
        return;
      }
      setLoadedState("error");
    };

    return () => {
      active = false;
    };
  }, [src, srcSet]);

  return loadedState;
};

export default useImageLoaded;
