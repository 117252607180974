import { createCampaign } from "@/apis/v1/campaign";
import { useSnackbarDispatch } from "@/context/SnackbarContext";
import { messages } from "@/messages";
import { CAMPAIGN_TYPE } from "@/modules/Campaign/campaign.constant";
import routes from "@/routes";
import convertDateToUTC from "@/utils/convertDateToUTC";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { validationSchema } from "../../../campaign.validation";
import { useCreateOrEditForm } from "../../campaign.hook";
import InfoForm from "../InfoForm";
type ICreateCampaign = yup.InferType<ReturnType<typeof validationSchema>>;
dayjs.extend(utc);
export default function CreateCampaign() {
  const navigate = useNavigate();

  const { mutateAsync, isLoading } = useMutation(createCampaign);
  const { setSnackbarState } = useSnackbarDispatch();
  const { state } = useLocation();
  const campaign = JSON.parse(state);

  const { rForm } = useCreateOrEditForm(campaign);
  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    getValues,
    setValue,
  } = rForm;

  async function onFormSubmit(data: ICreateCampaign) {
    try {
      const payload = {
        name: data.name,
        startDate: convertDateToUTC(data.startDate),
        executionType: data.executionType,
        advertiserCompanyId: Number(data.advertiserCompanyId),
        display: {
          budgetValue: data.totalImpression,
        },
        lineItems: [
          {
            channel: "display",
            start_date: convertDateToUTC(data.startDate), // same as campaign start date
            end_date: data.endDate ? convertDateToUTC(data.endDate) : null,
            line_item_name: data.name, // same as campaign name
            line_item_type_id: Number(data.lineItemType), // 0 for banner
            line_item_budget: Number(data.totalImpression),
            frequency_cap: [
              {
                impressions: Number(data.numberOfAds),
                durationType: data.durationType,
              },
            ],
            audienceIds: [data.audienceIds],
            creatives: data.creatives,
            active: data.status,
            dateType: data.dateType,
          },
        ],
      };
      await mutateAsync({ ...payload, type: CAMPAIGN_TYPE.QUICK });
      navigate(
        `${routes.DASHBOARD}/${routes.CAMPAIGNS.INDEX}/${routes.CAMPAIGNS.QUICK_CAMPAIGN.INDEX}`
      );
      setSnackbarState({
        open: true,
        message: messages.createdSuccessfully("Campaign"),
      });
    } catch (error: any) {
      setSnackbarState({
        open: true,
        severity: "error",
        message: error.errMsg ?? messages.somethingWentWrong,
      });
    }
  }

  return (
    <InfoForm
      control={control}
      errors={errors}
      onFormSubmit={handleSubmit(onFormSubmit)}
      trigger={trigger}
      getValues={getValues}
      setValue={setValue}
      isLoading={isLoading}
    />
  );
}
