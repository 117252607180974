import client from "../client";
import { QueryFunctionContext } from "@tanstack/react-query";
import appendQueryParams from "@/utils/appendQueryParams";

export function createCreative({
  payload,
  type,
}: {
  payload: any;
  type: string;
}) {
  return client.post(`/creatives/${type}`, payload);
}
export function editCreative({
  payload,
  type,
  id,
}: {
  payload: any;
  type: string;
  id: number;
}) {
  return client.put(`/creatives/${type}/${id}`, payload);
}
export function getAssets(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/creatives`, data.meta?.queryParams ?? {})
  );
}
export function getVisitorInsightAudience(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(`/audiences/visitor-insights`, data.meta?.queryParams ?? {})
  );
}
export function getAssetDetails(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `/creatives/${data.meta?.type}/${data.meta?.id}/lineitems`,
      data.meta?.queryParams ?? {}
    )
  );
}
export function refreshAds({
  id,
  type,
  queryParams,
}: {
  id: number;
  type: string;
  queryParams?: any;
}) {
  return client.put(
    appendQueryParams(`/creatives/${type}/${id}/refresh`, queryParams ?? {})
  );
}
export function deleteAd({
  id,
  type,
  queryParams,
}: {
  id: number;
  type: string;
  queryParams?: any;
}) {
  return client.delete(
    appendQueryParams(`/creatives/${type}/${id}`, queryParams ?? {})
  );
}
export function createAssets({ type, data }: { type: string; data: any }) {
  return client.post(`/creatives/${type}/assets`, data);
}

export function getCreativeAddons(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `creatives/display/creative_addon`,
      data.meta?.queryParams ?? {}
    )
  );
}
export function getImageByUrn(data: QueryFunctionContext) {
  return client.get(
    appendQueryParams(
      `creatives/linkedin/assets/${data?.meta?.id}`,
      data.meta?.queryParams ?? {}
    )
  );
}

export function getBwCreativeDetails(data: QueryFunctionContext) {
  return client.get(`creatives/display/details/${data?.meta?.id}`);
}
