import GeneralCampaign from "@/modules/Campaign/GeneralCampaign";
import CreateOrEditCampaign from "@/modules/Campaign/GeneralCampaign/CreateOrEditCampaign";
import routes from "@/routes";
import { lazy } from "react";
import LineItemRoutes from "./lineItem.routes";
const ListCampaign = lazy(
  () => import("@/modules/Campaign/GeneralCampaign/ListCampaign")
);
const DetailCampaign = lazy(
  () => import("@/modules/Campaign/GeneralCampaign/DetailCampaign")
);

const GeneralCampaignRoutes = {
  element: <GeneralCampaign />,
  path: routes.CAMPAIGNS.GENERAL_CAMPAIGN.INDEX,

  children: [
    {
      element: <ListCampaign />,
      path: "",
    },
    {
      element: <CreateOrEditCampaign />,
      path: routes.CAMPAIGNS.GENERAL_CAMPAIGN.CREATE,
    },
    {
      element: <CreateOrEditCampaign isEdit />,
      path: routes.CAMPAIGNS.GENERAL_CAMPAIGN.EDIT(),
    },
    {
      element: <DetailCampaign />,
      path: routes.CAMPAIGNS.GENERAL_CAMPAIGN.VIEW(),
    },
    LineItemRoutes,
  ],
};

export default GeneralCampaignRoutes;
