import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import StyledSwitch from "../StyledSwitch.style";

export default function CustomSwitch({
  check,
  disabled,
  setCheck,
  isLabel = true,
  customHandleCheck,
}: CustomSwitchProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck && setCheck(event.target.checked);
  };

  return (
    <FormControlLabel
      sx={{ marginRight: 0 }}
      control={
        <StyledSwitch
          checked={check}
          disabled={disabled}
          onChange={customHandleCheck ?? handleChange}
        />
      }
      label={isLabel ? (check ? "active" : "inactive") : ""}
    />
  );
}

interface CustomSwitchProps {
  check: boolean | undefined;
  disabled?: boolean;
  setCheck: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  isLabel: boolean;
  customHandleCheck: any;
}
