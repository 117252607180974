import { getCompanies, getCompanyById } from "@/apis/v1/company";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  AdvertiserSwitchState,
  ICreateOrEditAdvertiser,
  ICreateOrEditAdvertiserPayload,
} from "./advertiser.interface";
import { pick } from "lodash";
import { filterDataAccordingToAllowedAdvertisers } from "./advertiser.validation";
import { SMARTLEAD_ADVERTISER_COMPANY_ASSOCIATION_TYPE } from "./advertiser.constant";

export const ADVERTISERS_LIST_KEYS = {
  default: () => ["advertisers", "list"],
  withPagination: ({ page, size, text }: any) => [
    ...ADVERTISERS_LIST_KEYS.default(),
    { page, size, text },
  ],
} as const;

type useAdvertiserListArgs = {
  onSuccessFunction?: (data: any) => void;
  queryParams?: {
    agencyId?: number;
    text: string;
  };
  enabled?: boolean;
};
type usePaginatedAdvertiserListArgs = {
  onSuccessFunction?: (data: any) => void;
  queryParams?: {
    agencyId?: number;
    page?: number;
    size?: number;
    text: string;
  };
};

export function useAdvertisersList({
  onSuccessFunction,
  queryParams,
  enabled = true,
}: useAdvertiserListArgs = {}) {
  return useQuery(ADVERTISERS_LIST_KEYS.default(), getCompanies, {
    meta: {
      queryParams: {
        type: "advertisers",
        ...queryParams,
        isPagination: false,
      },
    },
    enabled,
    select: (data) => filterDataAccordingToAllowedAdvertisers("id")(data?.rows),
    onSuccess: (data) => onSuccessFunction?.(data),
  });
}
export function usePaginatedAdvertisersList({
  onSuccessFunction,
  queryParams,
}: usePaginatedAdvertiserListArgs = {}) {
  return useQuery(
    ADVERTISERS_LIST_KEYS.withPagination({
      page: queryParams?.page || 0,
      size: queryParams?.size || 10,
      text: queryParams?.text,
    }),
    getCompanies,
    {
      meta: {
        queryParams: {
          type: "advertisers",
          ...queryParams,
          isPagination: true,
        },
      },
      select: filterDataAccordingToAllowedAdvertisers("id"),
      onSuccess: (data) => onSuccessFunction?.(data),
    }
  );
}
export function useAdvertiser(id?: string | number) {
  return useQuery(["advertiser", id], getCompanyById, {
    meta: {
      id,
    },
    enabled: Boolean(id),
  });
}

export function useSwitchState(state?: AdvertiserSwitchState) {
  const [switchState, setSwitchState] = useState({
    display: state?.display ?? false,
    linkedin: state?.linkedin ?? false,
    facebook: state?.facebook ?? false,
    heyreach: state?.heyreach ?? false,
    smartlead: state?.smartlead ?? false,
  });

  return { switchState, setSwitchState };
}

export function generatePayload({
  activeState,
  data,
  adAccounts,
}: {
  activeState: AdvertiserSwitchState;
  data: ICreateOrEditAdvertiser;
  adAccounts: any;
  advertiserId?: any;
}) {
  let payload: ICreateOrEditAdvertiserPayload = {
    name: data.name,
    canConnectLinkedin: Boolean(data.canConnectLinkedin),
  };

  if (activeState.linkedin) {
    if (!data.canConnectLinkedin) {
      if (data.linkedinAdAccountId) {
        const adAccount = adAccounts?.elements?.find(
          (item: any) => item.id === data.linkedinAdAccountId
        );
        data["linkedinPageId"] =
          adAccount.reference.split("urn:li:organization:")[1] ??
          adAccount.reference.split("urn:li:company:")[1];
        payload = {
          ...payload,
          ...pick(data, [
            "linkedinPageId",
            "linkedinCurrency",
            "linkedinAdAccountId",
          ]),
        };
      } else {
        payload = {
          ...payload,
          ...pick(data, ["linkedinPageId", "linkedinCurrency"]),
        };
      }
    }
  }

  if (activeState.display) {
    payload = {
      ...payload,
      ...pick(data, ["domain"]),
    };
  }

  if (activeState.facebook) {
    payload = {
      ...payload,
      ...pick(data, ["fbAdAccountId", "fbPageId"]),
    };
  }

  if (activeState.heyreach) {
    payload = {
      ...payload,
      ...pick(data, ["canConnectHeyreach"]),
    };
  }
  if (activeState.smartlead) {
    let smartlead = { ...data.smartlead };
    if (
      data.smartlead.companyAssociationType &&
      data.smartlead.companyAssociationType !==
        SMARTLEAD_ADVERTISER_COMPANY_ASSOCIATION_TYPE.IMPORTED_ACCOUNT
    )
      smartlead = {
        companyAssociationType: data.smartlead.companyAssociationType,
      };
    payload = {
      ...payload,
      smartlead,
    };
  }

  return payload;
}

export function generateParams({
  activeState,
  data,
}: {
  activeState: AdvertiserSwitchState;
  data: any;
}) {
  if (activeState.linkedin) {
    return { linkedinAccountType: data.linkedinAccountType };
  }

  return {};
}

export function useChannelCardDisable(
  data: {
    display: {
      userDisplayId: number | null | undefined;
      domain: string | undefined | null;
    };
    linkedin: {
      userBillingLinkedinId: number | null | undefined;
      linkedinPageId: number | null | undefined;
      canConnectLinkedin: boolean | null | undefined;
    };
    facebook: {
      facebookId: number | null | undefined;
      fbAdAccountId: string | null | undefined;
    };
    heyreach: {
      userHeyreachClientId: number | null | undefined;
      heyreachAdvertiserId: number | null | undefined;
    };
    smartlead: {
      agencyConnected: boolean | undefined;
      advertiserConnected: boolean | undefined;
    };
  },
  isEdit: boolean
) {
  const disabledState = {
    isDisplayDisabled: true,
    isLinkedinDisabled: true,
    isFacebookDisabled: true,
    isHeyreachDisabled: true,
    isSmartleadDisabled: true,
  };

  if (data.display.userDisplayId) {
    if (isEdit) {
      if (!data.display.domain) disabledState.isDisplayDisabled = false;
    } else disabledState.isDisplayDisabled = false;
  }
  if (data.linkedin.userBillingLinkedinId) {
    if (isEdit) {
      if (!(data.linkedin.linkedinPageId || data.linkedin.canConnectLinkedin))
        disabledState.isLinkedinDisabled = false;
    } else disabledState.isLinkedinDisabled = false;
  }
  if (data.facebook.facebookId) {
    if (isEdit) {
      if (!data.facebook.fbAdAccountId)
        disabledState.isFacebookDisabled = false;
    } else disabledState.isFacebookDisabled = false;
  }

  if (data.heyreach.userHeyreachClientId) {
    if (isEdit) {
      if (!data.heyreach.heyreachAdvertiserId)
        disabledState.isHeyreachDisabled = false;
    } else disabledState.isHeyreachDisabled = false;
  }
  if (data.smartlead.agencyConnected) {
    if (isEdit) {
      if (!data.smartlead.advertiserConnected)
        disabledState.isSmartleadDisabled = false;
    } else disabledState.isSmartleadDisabled = false;
  }
  return disabledState;
}
