import * as React from "react";
// mui imports
import { InputFieldType } from "@/types";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { SxProps } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { useTheme } from "@mui/system";
// custom imports

export default function CustomSearch({
  placeholder,
  showIcon = true,
  position = "start",
  type = "primary",
  disabled = false,
  styles = {},
  search,
  setSearch,
}: CustomSearchProps): ReturnType<React.FC> {
  const theme = useTheme();

  const secondaryStyles =
    type === "secondary"
      ? {
          backgroundColor: "background.default",
          ".MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${theme.palette.accent.light}`,
          },
          ".MuiOutlinedInput-input": {
            backgroundColor: "background.default",
            minHeight: "39px",
          },
        }
      : {};

  return (
    <OutlinedInput
      sx={{ ...secondaryStyles, ...styles }}
      startAdornment={
        showIcon && position === "start" ? <SearchOutlinedIcon /> : null
      }
      endAdornment={
        showIcon && position === "end" ? <SearchOutlinedIcon /> : null
      }
      placeholder={placeholder}
      disabled={disabled}
      value={search}
      onChange={(
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      ) => setSearch(event.target.value)}
    />
  );
}

export interface CustomSearchProps {
  placeholder?: string;
  icon?: JSX.Element;
  position?: string;
  type?: InputFieldType;
  showIcon?: boolean;
  disabled?: boolean;
  styles?: SxProps;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}
