import QuickCompaign from "@/modules/Campaign/QuickCampaign";
import CreateOrEditCampaign from "@/modules/Campaign/QuickCampaign/CreateOrEditCampaign";
import routes from "@/routes";
import { lazy } from "react";
import LineItemRoutes from "./lineItem.routes";
const ListCampaign = lazy(
  () => import("@/modules/Campaign/QuickCampaign/ListCampaign")
);

const QuickCampaignRoutes = {
  element: <QuickCompaign />,
  path: routes.CAMPAIGNS.QUICK_CAMPAIGN.INDEX,

  children: [
    {
      element: <ListCampaign />,
      path: "",
    },
    {
      element: <CreateOrEditCampaign />,
      path: routes.CAMPAIGNS.QUICK_CAMPAIGN.CREATE,
    },
    {
      element: <CreateOrEditCampaign isEdit />,
      path: routes.CAMPAIGNS.QUICK_CAMPAIGN.EDIT(),
    },
    LineItemRoutes,
  ],
};

export default QuickCampaignRoutes;
