import routes from "@/routes";
import { lazy } from "react";

const Conversion = lazy(() => import("@/modules/Conversion"));
const ListConversion = lazy(
  () => import("@/modules/Conversion/ListConversion")
);
const CreateOrEditConversion = lazy(
  () => import("@/modules/Conversion/CreateOrEditConversion/")
);

const ConversionRoutes = {
  element: <Conversion />,
  path: routes.CONVERSION.INDEX,

  children: [
    {
      element: <ListConversion />,
      path: "",
    },
    {
      element: <CreateOrEditConversion />,
      path: routes.CONVERSION.CREATE,
    },
    {
      element: <CreateOrEditConversion isEdit />,
      path: routes.CONVERSION.EDIT(),
    },
    {
      element: <CreateOrEditConversion isEdit={false} isReadonly />,
      path: routes.CONVERSION.VIEW(),
    },
  ],
};

export default ConversionRoutes;
